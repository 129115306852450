/*mixins*/


@mixin omnes {
	font-family: "omnes-pro",sans-serif;
}

@mixin transition {
	transition: all 0.3s ease-out 0.07s;
}

@mixin bouton {
	font-weight: 600;
	font-size: $f14;
	text-transform: uppercase;
	border-width: 2px;
	border-style: solid;
	display: inline-block;
	padding: 10px 20px 12px;
}

@mixin titre2 {
	margin-bottom: 20px;
	font-size: $f26;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 0.04em;
	text-align: center;
	&::after{
		display: block;
		content: '';
		width: 52px;
		height: 2px;
		margin: 9px auto;
	}
	@media screen and (max-width: 900px){
		font-size: $f20;
		&::after{
			margin: 5px auto;
		}
	}
}

@mixin titre3{
	margin: 2.3em 0 1em 0;
	font-weight: 600;
	font-size: $f28;
}


@mixin pagetitre2{
	font-size: $f60;
}
@mixin clear{
	&::after{  
		content: '';
		clear: both;
		display: table;
	}
}