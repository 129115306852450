
/*------------------HEADER-------
	--------------------------------
	--------*/


/*------------top header---------*/


.topheader{
	background: $neutremoyen;
}
.topheader_logo{
	position: absolute;
	z-index: 3;
}
.topheader_nav{
	text-align: right;
}


.topheader_liensimple{
	display: inline-block;
	height: 50px;
	line-height: 50px;
	font-size: $f14;
	padding: 0 14px;
	vertical-align: middle;
	&:hover, &:active, &:focus{
		background: $neutremoyen;
	}
}
.topheader_recherche{
	vertical-align: middle;
	height: 50px;
	line-height: 50px;
	display: inline-block;
	padding: 0 14px;
	img{
		width: 18px;
		vertical-align: middle;
	}
	&:hover, &:active, &:focus{
		background: $neutremoyen;
	}
}
.topheader_adherent{
	vertical-align: middle;
	display: inline-block;
	height: 100%;
	line-height: 50px;
	background: $thirdcolor url(../images/espace-adherent@2x.png) no-repeat left 15px center;
	background-size: 15px 15px;
	color: $light;
	text-transform: uppercase;
	font-size: $f14;
	font-weight: 600;
	padding: 0 17px 0 43px;
	&:hover, &:active, &:focus{
		background-color: $secondcoloralt;
	}
}


/*------------------menu-----------------------*/

.menu{
	background: $secondcolor;
	@include clear;
}
.menu_niv1ul{
	float: right;
}
.menu_niv1a{
	height: 100px;
	color: $light;
	text-transform: uppercase;
	font-size: $f14;
	font-weight: 600;
	display: table-cell;
	vertical-align: middle;
	text-align: left;
	max-width: 150px;
	padding: 0 18px;
	&:last-child{
		padding: 0 0 0 18px;
	}
	span{
		display: inline-block;
		padding: 8px 0;
		border-bottom: solid 2px rgba(234,60,52,0);
		transition: all 0.3s ease-out 0.07s;
	}
	&:hover, &:active, &:focus{
		.menu_niv1span{
			border-bottom-color: $light;
			cursor: pointer;
		}
	}
	
}

/*--------------------menu déroulant--------------------*/

.menu_niv1a{
	position: relative;
	&:hover,&:active,&:focus{
		.megadropdown, .menu_niv2ul{
			visibility: visible;
			opacity: 1;
		}
	}
	&.conteneur_megadropdown{
		position: static;
	}
}

.menu_niv2ul{
	visibility: hidden;
	opacity: 0;
	@include transition;
	position: absolute;
	background: $secondcoloralt;
	z-index: 2;
	top: 100px;
	width: 155px;
	left: 50%;
	transform: translateX(-50%);	
	padding: 20px;
	text-transform: none;
	.menu_niv2li{
		padding: 6px 0;
		line-height: 1.1;
		a{
			display: block;
		}
		a:hover, a:active, a:focus{
			color: $neutremoyen;
		}
	}
}


.menu_niv1a:last-of-type{
	.menu_niv2ul{
		left: -3px;
		transform: none;
	}
}

.megadropdown{
	visibility: hidden;
	opacity: 0;
	@include transition;
	position: absolute;
	background: $secondcoloralt;
	z-index: 2;
	left: 0;
	right: 0;
	top: 150px;
	padding: 35px 0 35px;
	min-height: 260px;
	.mega_niv2ul{
	}
	.mega_niv2li{
		float: left;
		vertical-align: top;
		width: 16.666%; /*200/1200*/
		margin-right: 4.166%; /*50/1200*/
		&:nth-of-type(5n){
			margin-right: 0;
		}
	}
	.mega_niv2wrapper{
		height: 50px;
	//	line-height: 50px; on enlever le line-height pour aligner les titres en haut
	}
	.mega_niv2span{
		line-height: 1.1;
	}
	a.mega_niv2span {
		padding: 8px;
		display: inline-block;
		&:hover, &:active, &:focus{
			color: $neutremoyen;			
		}
	}
	.mega_niv3ul{
		padding: 0 0 0 16px;
	}
	.mega_niv3li{
		text-transform: none;
		list-style-type: square;
		line-height: 1.1;
		padding: 6px 0;
		a:hover, a:active, a:focus{
			color: $neutremoyen;
		}
	}
}




/*------------------search---------------*/

.topheader_recherche{
	position: relative;
	.search_conteneur{
		width: 250px;
		background: $typocolor;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		text-align: center;
		opacity: 0;
		@include transition;
		visibility: hidden;
		transition-property: opacity;
		z-index: 2;
	}
	.search_input{
		@include omnes;
		border: none;
		background: $typocolor;
		color: $light;
		font-size: $f15;
		line-height: 1.2;
	}
	.search_button{
		@include omnes;
		border: none;
		background: $typocolor;
		color: $light;
		font-size: $f15;
		cursor: pointer;
		line-height: 1.2;
	}
	&:active, &:focus, &:hover {
		.search_conteneur{
			opacity: 1;
			visibility: visible;
		}	
	}
}

/*menu fixe*/
.logo_fixe{
	display: none;
}
.menufixe{
	position: fixed;
	width: 100%;
	z-index: 3;
	top: 0;
	.logo_fixe{
		display: block;
		float: left;
		height: 100px;
		background: $light;
		img{
			display: block;
		}
	}
	.megadropdown{
		top: 100px;
	}
}



/*Positionnement du menu fixe quand l'user est connecté*/

.admin{
	.megadropdown{
		top: 182px;
	}
	.menufixe{
		top: 32px;
		.megadropdown{
			top: 100px;
		}
	}
}











/*-------------mediaqueries--------------*/

@media screen and (max-width: 1240px) {
	.megadropdown{
		padding-left: 20px;
		padding-right: 20px;
	}	
	.menu_niv1a{
		&:last-child{
			padding: 0 17px 0 17px;
		}
	}
	.menu_niv2ul{
		max-width: 120px;
	}
	.menu_niv1a:last-of-type{
		.menu_niv2ul{
			left: 50%;
			transform: translateX(-50%);
		}	
	}
}

@media screen and (max-width:1161px) {
	.topheader_logo, .logo_fixe {
		width: 34.821%;/*398/1143*/
	}
	.menu_niv1ul{
		width: 65.179%;
	}
	.menu_niv1a{
		font-size: $f13;
		padding: 0 17px;
		&:last-child{
			padding: 0 17px 0 17px;
		}
	}
}


@media screen and (max-width:1015px) {
	.veganburger{
		display: block;
	}
	.topheader{
		background: none;
	}
	.topheader_logo {
		width: 69.375%;/*222/320*/
		max-width: 398px;
	}
	.menu, .topheader_nav{
		display: none;
	}
	.topheader_logo{
		position: static;
		margin: 0 auto;
		img{
			display: block;
		}
	}
}

