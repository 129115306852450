
/* PAge*/

 .conteneur_principal{
	 @include clear;
	 padding-bottom: 160px;
	 position: relative;
	 .coldroiteetroite{
		 margin-top: -21px;
	 }
	 .colgauchelarge{
		 margin-top: 25px;
		 padding-right: 40px;
	 }
 }
 
 .coldroiteetroite{
	 .sb_actualite, .sb_agenda, .sb_tweet, .sb_rs, .trophee, .sb_profil, .sb_activitesrecentes{
		 margin-bottom: 40px;
	 }
 }
 
 
 /*test sans margin bottom aux elmenets de sidebar*/
.coldroiteetroite{
	 .sb_actualite, .sb_agenda, .sb_tweet, .sb_rs, .trophee, .sb_profil, .sb_activitesrecentes{
		 margin-bottom: 0px;
	 }
 }