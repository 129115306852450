
/*hamburger*/


.veganburger {
	cursor: pointer;
	position: fixed;
	width: 63px;
	height: 51px;
	top: 0px;
	left: 0px;
	z-index: 4;
	background: $light-op28;
	padding: 14px;
	span {
		border-radius: 3px;
		height: 3px;
		width: 35px;
		position: absolute;
		display: block;
		content: '';
		transition: all 0.6s ease-out 0.07s;
		background: $typocolor;
	}
	.pain{
		top: 14px;
	}
	.ketchup {
		top: 24px;
	}
	.tempeh {
		top: 34px; 
	}
	&.active {
		background: $secondcolor;

		.pain{
			top: 24px;
			transform: rotate(45deg);
		} 
		.ketchup {
			background-color: transparent;
		}
		.tempeh {
			top: 24px;
			transform: rotate(-45deg);
		}
		span{
			background: $light;
		box-shadow: none;	
		}
	}

}

/*positionnement menu fixe quand user est loggé*/

.admin{
	.veganburger{
		top: 45px;
	}
}