
/*couleurs*/

$typocolor: #5a225a; /*violet foncé*/
$violet2: #682464;
$mauve: #b490b2; /*mauve typo*/
$neutreclair: #eee9ef; /*mauve clair*/
$neutremoyen: #e2d9e3; /*mauve moyen-clair*/
$secondcolor: #ea3c34; /*rouge*/
$secondcoloralt: #d43b34; /*rouge plus foncé*/
$thirdcolor: #ec9e00; /*jaune*/

$light: #fff;
$light-op28: rgba(255, 255, 255, 0.28);
$light-op5: rgba(255, 255, 255, 0.5);
$dark: #000;
$gristresclair: #f9f9f9;

$typoop60: rgba(90,34,90,0.6);
$neutreop60: rgba(238,233,239,0.6);



/*taille typo*/

$f12: 0.75rem;
$f13: 0.8125rem;
$f14: 0.875rem;
$f15: 0.9375rem;
$f16: 1rem;
$f17: 1.0625rem;
$f18: 1.125rem;
$f19: 1.1875rem;
$f20: 1.25rem;
$f24: 1.5rem;
$f26: 1.625rem;
$f28: 1.75rem;
$f32: 2rem;
$f36: 2.25rem;
$f60: 3.75rem;

/*line-height*/