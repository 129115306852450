
/*Menu mobile*/

body.menu-ouvert{
	overflow: hidden;
}

.menumob{
	position: fixed;
	top: 0;
	bottom: 0;
	background: $secondcolor;
	width: 300px;
	padding: 56px 0 0 0;
	transform: translateX(-100%);
	@include transition;
	z-index: 3;
	.menu_niv1ul--mob{
		overflow-y: auto;
		position: absolute;
		bottom: 92px;
		top: 56px;
	}
	.menu_niv1li{
		
	}
	.menu_niv1a{
		display: block;
		color: $light;
		height: auto;
		max-width: 100%;
		font-size: $f14;
		padding: 10px 40px 10px 17px;
		position: relative;
		span{
			padding: 0;
			border: 0;
		}
		.ico_toggle{
			position: absolute;
			right: 17px;
			top: 7px;
			display: inline;
		}
	}
	&.active{
		transform: translateX( 0px );
	}
	.menumob_footer{
		position: absolute;
		width: 100%;
		bottom: 0;
		height: 92px;
		background: $secondcoloralt;
		.search_form{
			padding: 17px 17px 17px 45px;
			background: url(../images/mob-loupe@2x.png) no-repeat left 17px center;
			background-size: 18px 18px;
		}
		.menumob_adherent{
			padding: 17px 17px 17px 45px;
			background: url(../images/espace-adherent@2x.png) no-repeat left 17px center;
			background-size: 18px 18px;
			color: $light;
			text-transform: uppercase;
			font-size: $f14;
			font-weight: 600;
		}
		.search_input{
			@include omnes;
			border: none;
			background: $secondcoloralt;
			color: $light;
			font-size: $f15;
			line-height: 1.2;
		}
		.search_button{
			@include omnes;
			border: none;
			background: $secondcoloralt;
			color: $light;
			font-size: $f15;
			cursor: pointer;
			line-height: 1.2;
		}
	}
}

.effetfiltre--mob{
	background: rgba(238, 233, 239, 0.6) none repeat scroll 0 0;
	top: 0;
	bottom: 0;
    left: -100%;
    position: fixed;
	transition: all 0.3s ease-out 0.07s;
    width: 100%;
    z-index: 2;
    &.active{
	    left: 0;
    }
}



/*icone toggle*/

.ico_toggle{
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2px solid $light;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
  
  &:before {
    content: "";
    display: block;
    position: absolute;
    background-color: $light;
    width: 10px;
    height: 2px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
  }
  
  &:after {
    content: "";
    display: block;
    position: absolute;
    background-color: $light;
    width: 2px;
    height: 10px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
  }
}

.menu_niv1a.active, .mega_niv2title.active{
	.ico_toggle{
    	background-color: $light;
		&:after {
			background-color: $secondcolor;
    	}
		&:before {
			background-color: $secondcolor;
    	}
		transform: rotate(225deg);
	}
}  






/*Sous menu mobile*/

.mob-megadropdown {
	color: $light;
	font-weight: 600;
	padding: 0 40px 0 17px;
	background: $light-op28;
	.mega_niv2li{
		padding: 8px 0px 8px 17px;
	}
	.mega_niv2title{
		display: block;
		padding: 5px 30px 7px 0;
		font-size: $f14;
		text-transform: uppercase;
		position: relative;
		.ico_toggle{
			position: absolute;
			right: 0px;
			top: 4px;
			display: inline;
		}
	}
	.mega_niv3ul{
		padding: 0 0 0 16px;
	}
	.mega_niv3li{
		font-size: $f14;
		padding-right: 30px;
		list-style-type: square;
		a{
			display: block;
			padding: 5px 0;
		}
		a:active{
			color: $neutremoyen;
		}
	}
}


.mob_niv2ul{
	padding: 0 40px 0 34px;
	background: $light-op28;
	.menu_niv2li{
		a{
			display: block;
			padding: 6px 0;
			line-height: 1.1;
			color: $light;
			font-size: $f14;
			font-weight: 600;
			&:active{
				color: $neutremoyen;
			}
		}	
			
	}
}


/*positionnement menu fixe quand user est loggé*/

.admin{
	.menumob{
		top: 32px;
	}
}