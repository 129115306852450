@charset "UTF-8";
/*!
Theme Name:
Description: thème sur-mesure conçu pour
Author: 
Author URI: 
*/
/*! jQuery UI - v1.12.0 - 2016-09-07
* http://jqueryui.com
* Includes: core.css, menu.css, selectmenu.css, button.css
* Copyright jQuery Foundation and other contributors; Licensed MIT */
/* Layout helpers
----------------------------------*/
.ui-helper-hidden {
  display: none; }

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none; }

.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
  content: "";
  display: table;
  border-collapse: collapse; }

.ui-helper-clearfix:after {
  clear: both; }

.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: Alpha(Opacity=0);
  /* support: IE8 */ }

.ui-front {
  z-index: 100; }

/* Interaction Cues
----------------------------------*/
.ui-state-disabled {
  cursor: default !important;
  pointer-events: none; }

/* Icons
----------------------------------*/
.ui-icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: -.25em;
  position: relative;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat; }

.ui-widget-icon-block {
  left: 50%;
  margin-left: -8px;
  display: block; }

/* Misc visuals
----------------------------------*/
/* Overlays */
.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.ui-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  outline: 0; }

.ui-menu .ui-menu {
  position: absolute; }

.ui-menu .ui-menu-item {
  margin: 0;
  cursor: pointer;
  /* support: IE10, see #8844 */
  list-style-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"); }

.ui-menu .ui-menu-item-wrapper {
  position: relative;
  padding: 3px 1em 3px .4em; }

.ui-menu .ui-menu-divider {
  margin: 5px 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  border-width: 1px 0 0 0; }

.ui-menu .ui-state-focus,
.ui-menu .ui-state-active {
  margin: -1px; }

/* icon support */
.ui-menu-icons {
  position: relative; }

.ui-menu-icons .ui-menu-item-wrapper {
  padding-left: 2em; }

/* left-aligned */
.ui-menu .ui-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: .2em;
  margin: auto 0; }

/* right-aligned */
.ui-menu .ui-menu-icon {
  left: auto;
  right: 0; }

.ui-selectmenu-menu {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  display: none; }

.ui-selectmenu-menu .ui-menu {
  overflow: auto;
  overflow-x: hidden;
  padding-bottom: 1px; }

.ui-selectmenu-menu .ui-menu .ui-selectmenu-optgroup {
  font-size: 1em;
  font-weight: bold;
  line-height: 1.5;
  padding: 2px 0.4em;
  margin: 0.5em 0 0 0;
  height: auto;
  border: 0; }

.ui-selectmenu-open {
  display: block; }

.ui-selectmenu-text {
  display: block;
  margin-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis; }

.ui-selectmenu-button.ui-button {
  text-align: left;
  white-space: nowrap;
  width: 14em; }

.ui-selectmenu-icon.ui-icon {
  float: right;
  margin-top: 0; }

.ui-button {
  padding: .4em 1em;
  display: inline-block;
  position: relative;
  line-height: normal;
  margin-right: .1em;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Support: IE <= 11 */
  overflow: visible; }

.ui-button,
.ui-button:link,
.ui-button:visited,
.ui-button:hover,
.ui-button:active {
  text-decoration: none; }

/* to make room for the icon, a width needs to be set here */
.ui-button-icon-only {
  width: 2em;
  box-sizing: border-box;
  text-indent: -9999px;
  white-space: nowrap; }

/* no icon support for input elements */
input.ui-button.ui-button-icon-only {
  text-indent: 0; }

/* button icon element(s) */
.ui-button-icon-only .ui-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -8px; }

.ui-button.ui-icon-notext .ui-icon {
  padding: 0;
  width: 2.1em;
  height: 2.1em;
  text-indent: -9999px;
  white-space: nowrap; }

input.ui-button.ui-icon-notext .ui-icon {
  width: auto;
  height: auto;
  text-indent: 0;
  white-space: normal;
  padding: .4em 1em; }

/* workarounds */
/* Support: Firefox 5 - 40 */
input.ui-button::-moz-focus-inner,
button.ui-button::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * Owl Carousel v2.1.0
 * Copyright 2013-2016 David Deutsch
 * Licensed under MIT (https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE)
 */
.owl-carousel, .owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative; }

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1; }

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y; }

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none; }

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d; }

.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
  display: none; }

.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-loaded {
  display: block; }

.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

.owl-carousel.owl-hidden {
  opacity: 0; }

.owl-carousel.owl-refresh .owl-item {
  display: none; }

.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab; }

.owl-carousel.owl-rtl {
  direction: rtl; }

.owl-carousel.owl-rtl .owl-item {
  float: right; }

.no-js .owl-carousel {
  display: block; }

.owl-carousel .animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.owl-height {
  transition: height .5s ease-in-out; }

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s ease; }

.owl-carousel .owl-item img.owl-lazy {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url(owl.video.play.png) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: -webkit-transform .1s ease;
  transition: transform .1s ease; }

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transform: scale(1.3, 1.3);
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-play-icon, .owl-carousel .owl-video-playing .owl-video-tn {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .4s ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/*----------- Reset -------------*/
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  text-decoration: none;
  color: inherit; }

img {
  width: auto;
  height: auto;
  max-width: 100%;
  box-sizing: border-box; }

strong {
  font-weight: 700; }

em {
  font-style: italic; }

img.alignright {
  float: right;
  margin: 0 0 1em 2.5em; }

img.alignleft {
  float: left;
  margin: 0 2.5em 1em 0; }

img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto; }

/*----------reset formulaire----------*/
input {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0; }

input[type=checkbox] {
  -webkit-appearance: checkbox; }

input[type=radio] {
  -webkit-appearance: radio; }

input[type=search] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box; }

/*------box sizing sur tous les éléments-------*/
* {
  box-sizing: border-box; }

/*------ transition sur tous les liens--------*/
a {
  transition: all 0.3s ease-out 0.07s; }

/*couleurs*/
/*violet foncé*/
/*mauve typo*/
/*mauve clair*/
/*mauve moyen-clair*/
/*rouge*/
/*rouge plus foncé*/
/*jaune*/
/*taille typo*/
/*line-height*/
/*mixins*/
/*general*/
body {
  font-family: "omnes-pro",sans-serif;
  color: #5a225a; }

.centre {
  max-width: 1200px;
  margin: 0 auto; }

.desk-display-none {
  display: none; }

strong {
  font-weight: 600; }

/*------------------HEADER-------
	--------------------------------
	--------*/
/*------------top header---------*/
.topheader {
  background: #e2d9e3; }

.topheader_logo {
  position: absolute;
  z-index: 3; }

.topheader_nav {
  text-align: right; }

.topheader_liensimple {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  font-size: 0.875rem;
  padding: 0 14px;
  vertical-align: middle; }
  .topheader_liensimple:hover, .topheader_liensimple:active, .topheader_liensimple:focus {
    background: #e2d9e3; }

.topheader_recherche {
  vertical-align: middle;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  padding: 0 14px; }
  .topheader_recherche img {
    width: 18px;
    vertical-align: middle; }
  .topheader_recherche:hover, .topheader_recherche:active, .topheader_recherche:focus {
    background: #e2d9e3; }

.topheader_adherent {
  vertical-align: middle;
  display: inline-block;
  height: 100%;
  line-height: 50px;
  background: #ec9e00 url(../images/espace-adherent@2x.png) no-repeat left 15px center;
  background-size: 15px 15px;
  color: #fff;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0 17px 0 43px; }
  .topheader_adherent:hover, .topheader_adherent:active, .topheader_adherent:focus {
    background-color: #d43b34; }

/*------------------menu-----------------------*/
.menu {
  background: #ea3c34; }
  .menu::after {
    content: '';
    clear: both;
    display: table; }

.menu_niv1ul {
  float: right; }

.menu_niv1a {
  height: 100px;
  color: #fff;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 600;
  display: table-cell;
  vertical-align: middle;
  text-align: left;
  max-width: 150px;
  padding: 0 18px; }
  .menu_niv1a:last-child {
    padding: 0 0 0 18px; }
  .menu_niv1a span {
    display: inline-block;
    padding: 8px 0;
    border-bottom: solid 2px rgba(234, 60, 52, 0);
    transition: all 0.3s ease-out 0.07s; }
  .menu_niv1a:hover .menu_niv1span, .menu_niv1a:active .menu_niv1span, .menu_niv1a:focus .menu_niv1span {
    border-bottom-color: #fff;
    cursor: pointer; }

/*--------------------menu déroulant--------------------*/
.menu_niv1a {
  position: relative; }
  .menu_niv1a:hover .megadropdown, .menu_niv1a:hover .menu_niv2ul, .menu_niv1a:active .megadropdown, .menu_niv1a:active .menu_niv2ul, .menu_niv1a:focus .megadropdown, .menu_niv1a:focus .menu_niv2ul {
    visibility: visible;
    opacity: 1; }
  .menu_niv1a.conteneur_megadropdown {
    position: static; }

.menu_niv2ul {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-out 0.07s;
  position: absolute;
  background: #d43b34;
  z-index: 2;
  top: 100px;
  width: 155px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  text-transform: none; }
  .menu_niv2ul .menu_niv2li {
    padding: 6px 0;
    line-height: 1.1; }
    .menu_niv2ul .menu_niv2li a {
      display: block; }
    .menu_niv2ul .menu_niv2li a:hover, .menu_niv2ul .menu_niv2li a:active, .menu_niv2ul .menu_niv2li a:focus {
      color: #e2d9e3; }

.menu_niv1a:last-of-type .menu_niv2ul {
  left: -3px;
  transform: none; }

.megadropdown {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-out 0.07s;
  position: absolute;
  background: #d43b34;
  z-index: 2;
  left: 0;
  right: 0;
  top: 150px;
  padding: 35px 0 35px;
  min-height: 260px; }
  .megadropdown .mega_niv2li {
    float: left;
    vertical-align: top;
    width: 16.666%;
    /*200/1200*/
    margin-right: 4.166%;
    /*50/1200*/ }
    .megadropdown .mega_niv2li:nth-of-type(5n) {
      margin-right: 0; }
  .megadropdown .mega_niv2wrapper {
    height: 50px; }
  .megadropdown .mega_niv2span {
    line-height: 1.1; }
  .megadropdown a.mega_niv2span {
    padding: 8px;
    display: inline-block; }
    .megadropdown a.mega_niv2span:hover, .megadropdown a.mega_niv2span:active, .megadropdown a.mega_niv2span:focus {
      color: #e2d9e3; }
  .megadropdown .mega_niv3ul {
    padding: 0 0 0 16px; }
  .megadropdown .mega_niv3li {
    text-transform: none;
    list-style-type: square;
    line-height: 1.1;
    padding: 6px 0; }
    .megadropdown .mega_niv3li a:hover, .megadropdown .mega_niv3li a:active, .megadropdown .mega_niv3li a:focus {
      color: #e2d9e3; }

/*------------------search---------------*/
.topheader_recherche {
  position: relative; }
  .topheader_recherche .search_conteneur {
    width: 250px;
    background: #5a225a;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    opacity: 0;
    transition: all 0.3s ease-out 0.07s;
    visibility: hidden;
    transition-property: opacity;
    z-index: 2; }
  .topheader_recherche .search_input {
    font-family: "omnes-pro",sans-serif;
    border: none;
    background: #5a225a;
    color: #fff;
    font-size: 0.9375rem;
    line-height: 1.2; }
  .topheader_recherche .search_button {
    font-family: "omnes-pro",sans-serif;
    border: none;
    background: #5a225a;
    color: #fff;
    font-size: 0.9375rem;
    cursor: pointer;
    line-height: 1.2; }
  .topheader_recherche:active .search_conteneur, .topheader_recherche:focus .search_conteneur, .topheader_recherche:hover .search_conteneur {
    opacity: 1;
    visibility: visible; }

/*menu fixe*/
.logo_fixe {
  display: none; }

.menufixe {
  position: fixed;
  width: 100%;
  z-index: 3;
  top: 0; }
  .menufixe .logo_fixe {
    display: block;
    float: left;
    height: 100px;
    background: #fff; }
    .menufixe .logo_fixe img {
      display: block; }
  .menufixe .megadropdown {
    top: 100px; }

/*Positionnement du menu fixe quand l'user est connecté*/
.admin .megadropdown {
  top: 182px; }

.admin .menufixe {
  top: 32px; }
  .admin .menufixe .megadropdown {
    top: 100px; }

/*-------------mediaqueries--------------*/
@media screen and (max-width: 1240px) {
  .megadropdown {
    padding-left: 20px;
    padding-right: 20px; }
  .menu_niv1a:last-child {
    padding: 0 17px 0 17px; }
  .menu_niv2ul {
    max-width: 120px; }
  .menu_niv1a:last-of-type .menu_niv2ul {
    left: 50%;
    transform: translateX(-50%); } }

@media screen and (max-width: 1161px) {
  .topheader_logo, .logo_fixe {
    width: 34.821%;
    /*398/1143*/ }
  .menu_niv1ul {
    width: 65.179%; }
  .menu_niv1a {
    font-size: 0.8125rem;
    padding: 0 17px; }
    .menu_niv1a:last-child {
      padding: 0 17px 0 17px; } }

@media screen and (max-width: 1015px) {
  .veganburger {
    display: block; }
  .topheader {
    background: none; }
  .topheader_logo {
    width: 69.375%;
    /*222/320*/
    max-width: 398px; }
  .menu, .topheader_nav {
    display: none; }
  .topheader_logo {
    position: static;
    margin: 0 auto; }
    .topheader_logo img {
      display: block; } }

/*hamburger*/
.veganburger {
  cursor: pointer;
  position: fixed;
  width: 63px;
  height: 51px;
  top: 0px;
  left: 0px;
  z-index: 4;
  background: rgba(255, 255, 255, 0.28);
  padding: 14px; }
  .veganburger span {
    border-radius: 3px;
    height: 3px;
    width: 35px;
    position: absolute;
    display: block;
    content: '';
    transition: all 0.6s ease-out 0.07s;
    background: #5a225a; }
  .veganburger .pain {
    top: 14px; }
  .veganburger .ketchup {
    top: 24px; }
  .veganburger .tempeh {
    top: 34px; }
  .veganburger.active {
    background: #ea3c34; }
    .veganburger.active .pain {
      top: 24px;
      transform: rotate(45deg); }
    .veganburger.active .ketchup {
      background-color: transparent; }
    .veganburger.active .tempeh {
      top: 24px;
      transform: rotate(-45deg); }
    .veganburger.active span {
      background: #fff;
      box-shadow: none; }

/*positionnement menu fixe quand user est loggé*/
.admin .veganburger {
  top: 45px; }

/*Menu mobile*/
body.menu-ouvert {
  overflow: hidden; }

.menumob {
  position: fixed;
  top: 0;
  bottom: 0;
  background: #ea3c34;
  width: 300px;
  padding: 56px 0 0 0;
  transform: translateX(-100%);
  transition: all 0.3s ease-out 0.07s;
  z-index: 3; }
  .menumob .menu_niv1ul--mob {
    overflow-y: auto;
    position: absolute;
    bottom: 92px;
    top: 56px; }
  .menumob .menu_niv1a {
    display: block;
    color: #fff;
    height: auto;
    max-width: 100%;
    font-size: 0.875rem;
    padding: 10px 40px 10px 17px;
    position: relative; }
    .menumob .menu_niv1a span {
      padding: 0;
      border: 0; }
    .menumob .menu_niv1a .ico_toggle {
      position: absolute;
      right: 17px;
      top: 7px;
      display: inline; }
  .menumob.active {
    transform: translateX(0px); }
  .menumob .menumob_footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 92px;
    background: #d43b34; }
    .menumob .menumob_footer .search_form {
      padding: 17px 17px 17px 45px;
      background: url(../images/mob-loupe@2x.png) no-repeat left 17px center;
      background-size: 18px 18px; }
    .menumob .menumob_footer .menumob_adherent {
      padding: 17px 17px 17px 45px;
      background: url(../images/espace-adherent@2x.png) no-repeat left 17px center;
      background-size: 18px 18px;
      color: #fff;
      text-transform: uppercase;
      font-size: 0.875rem;
      font-weight: 600; }
    .menumob .menumob_footer .search_input {
      font-family: "omnes-pro",sans-serif;
      border: none;
      background: #d43b34;
      color: #fff;
      font-size: 0.9375rem;
      line-height: 1.2; }
    .menumob .menumob_footer .search_button {
      font-family: "omnes-pro",sans-serif;
      border: none;
      background: #d43b34;
      color: #fff;
      font-size: 0.9375rem;
      cursor: pointer;
      line-height: 1.2; }

.effetfiltre--mob {
  background: rgba(238, 233, 239, 0.6) none repeat scroll 0 0;
  top: 0;
  bottom: 0;
  left: -100%;
  position: fixed;
  transition: all 0.3s ease-out 0.07s;
  width: 100%;
  z-index: 2; }
  .effetfiltre--mob.active {
    left: 0; }

/*icone toggle*/
.ico_toggle {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s; }
  .ico_toggle:before {
    content: "";
    display: block;
    position: absolute;
    background-color: #fff;
    width: 10px;
    height: 2px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
  .ico_toggle:after {
    content: "";
    display: block;
    position: absolute;
    background-color: #fff;
    width: 2px;
    height: 10px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }

.menu_niv1a.active .ico_toggle, .mega_niv2title.active .ico_toggle {
  background-color: #fff;
  transform: rotate(225deg); }
  .menu_niv1a.active .ico_toggle:after, .mega_niv2title.active .ico_toggle:after {
    background-color: #ea3c34; }
  .menu_niv1a.active .ico_toggle:before, .mega_niv2title.active .ico_toggle:before {
    background-color: #ea3c34; }

/*Sous menu mobile*/
.mob-megadropdown {
  color: #fff;
  font-weight: 600;
  padding: 0 40px 0 17px;
  background: rgba(255, 255, 255, 0.28); }
  .mob-megadropdown .mega_niv2li {
    padding: 8px 0px 8px 17px; }
  .mob-megadropdown .mega_niv2title {
    display: block;
    padding: 5px 30px 7px 0;
    font-size: 0.875rem;
    text-transform: uppercase;
    position: relative; }
    .mob-megadropdown .mega_niv2title .ico_toggle {
      position: absolute;
      right: 0px;
      top: 4px;
      display: inline; }
  .mob-megadropdown .mega_niv3ul {
    padding: 0 0 0 16px; }
  .mob-megadropdown .mega_niv3li {
    font-size: 0.875rem;
    padding-right: 30px;
    list-style-type: square; }
    .mob-megadropdown .mega_niv3li a {
      display: block;
      padding: 5px 0; }
    .mob-megadropdown .mega_niv3li a:active {
      color: #e2d9e3; }

.mob_niv2ul {
  padding: 0 40px 0 34px;
  background: rgba(255, 255, 255, 0.28); }
  .mob_niv2ul .menu_niv2li a {
    display: block;
    padding: 6px 0;
    line-height: 1.1;
    color: #fff;
    font-size: 0.875rem;
    font-weight: 600; }
    .mob_niv2ul .menu_niv2li a:active {
      color: #e2d9e3; }

/*positionnement menu fixe quand user est loggé*/
.admin .menumob {
  top: 32px; }

/*------------------
	---------SLIDER------------
	----------------------------*/
.slider .slide {
  position: relative;
  width: 100%;
  max-height: 500px;
  max-width: 1800px;
  margin: 0 auto; }
  .slider .slide img {
    display: block; }
  .slider .slide .blockquote_wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: -moz-linear-gradient(left, rgba(238, 233, 239, 0) 0%, rgba(238, 233, 239, 0.8) 8%, rgba(238, 233, 239, 0.8) 67%, rgba(238, 233, 239, 0) 77%, rgba(238, 233, 239, 0) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(238, 233, 239, 0) 0%, rgba(238, 233, 239, 0.8) 8%, rgba(238, 233, 239, 0.8) 67%, rgba(238, 233, 239, 0) 77%, rgba(238, 233, 239, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(238, 233, 239, 0) 0%, rgba(238, 233, 239, 0.8) 8%, rgba(238, 233, 239, 0.8) 67%, rgba(238, 233, 239, 0) 77%, rgba(238, 233, 239, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00eee9ef', endColorstr='#00eee9ef',GradientType=1 );
    /* IE6-9 */
    display: flex; }
  .slider .slide .centre {
    margin: 20% auto;
    margin: auto; }
  .slider .slide blockquote {
    font-size: 2.25em;
    margin: auto 0;
    width: 66.667%;
    /*800/1200*/
    position: relative;
    padding-left: 4.5%;
    /*54/1200*/ }
    .slider .slide blockquote::before {
      content: '';
      position: absolute;
      background-image: url(../images/blockquote-before.png);
      background-repeat: no-repeat;
      top: -1.3em;
      left: 0.2em;
      width: 2.41em;
      height: 2.05em;
      background-size: contain; }
    .slider .slide blockquote::after {
      content: '';
      position: absolute;
      background-image: url(../images/blockquote-after.png);
      background-repeat: no-repeat;
      position: absolute;
      bottom: -1.9em;
      right: -1.4em;
      width: 2.41em;
      height: 2.05em;
      background-size: contain; }
  .slider .slide .citation {
    font-style: italic;
    line-height: 1.2;
    padding-bottom: 1.14em; }
    .slider .slide .citation strong {
      font-weight: 600; }
  .slider .slide .bouton {
    font-weight: 600;
    font-size: 0.875rem;
    text-transform: uppercase;
    border-width: 2px;
    border-style: solid;
    display: inline-block;
    padding: 10px 20px 12px;
    border-color: #5a225a; }
    .slider .slide .bouton:focus, .slider .slide .bouton:active, .slider .slide .bouton:hover {
      background: #5a225a;
      color: #fff; }
  .slider .slide .legend {
    float: right;
    text-align: right;
    line-height: 1.2;
    font-size: 1.125rem; }
    .slider .slide .legend span {
      display: block;
      font-size: 1rem; }

.slider .owl-prev {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: url(../images/slider-prev.png) no-repeat;
  font-size: 0;
  opacity: 0.5;
  width: 04.888%;
  /*88/1800*/
  height: 32%;
  /*160/500*/
  background-size: contain;
  transition: all 0.3s ease-out 0.07s; }
  .slider .owl-prev:focus, .slider .owl-prev:active, .slider .owl-prev:hover {
    opacity: 1; }

.slider .owl-next {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: url(../images/slider-next.png) no-repeat;
  font-size: 0;
  width: 04.888%;
  /*88/1800*/
  height: 32%;
  /*160/500*/
  background-size: contain;
  opacity: 0.5;
  transition: all 0.3s ease-out 0.07s; }
  .slider .owl-next:focus, .slider .owl-next:active, .slider .owl-next:hover {
    opacity: 1; }

@media screen and (max-width: 1400px) {
  .slider .slide blockquote {
    font-size: 2em; } }

@media screen and (max-width: 1200px) {
  .slider .slide blockquote {
    margin: 0 8%;
    font-size: 1.75em; } }

@media screen and (max-width: 1000px) {
  .slider .slide blockquote {
    font-size: 1.25em; } }

@media screen and (max-width: 800px) {
  .slider .slide .blockquote_wrapper {
    background: rgba(238, 233, 239, 0.8); }
  .slider .slide blockquote {
    font-size: 1.10em; } }

@media screen and (max-width: 700px) {
  .slider .slide blockquote {
    width: auto;
    padding: 0; }
    .slider .slide blockquote::before {
      left: -1.5em; } }

@media screen and (max-width: 640px) {
  .slider .slide .blockquote_wrapper {
    position: static;
    height: 300px; }
  .slider .slide blockquote {
    font-size: 1em; }
  .slider .owl-prev, .slider .owl-next {
    width: 22px;
    height: 40px; } }

/*gestion du z-index du carousel*/
.owl-carousel {
  z-index: 0 !important; }

/*commit de test*/
/*footer*/
footer {
  background: #ea3c34;
  min-height: 162px;
  position: relative; }
  footer::after {
    content: '';
    clear: both;
    display: table; }
  footer .centre {
    position: relative; }

.footer_haut {
  position: absolute;
  width: 100%;
  background: #f9f9f9;
  top: -110px; }
  .footer_haut::after {
    content: '';
    clear: both;
    display: table; }
  .footer_haut .titre2 {
    margin-bottom: 20px;
    font-size: 1.625rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.04em;
    text-align: center;
    color: #ea3c34;
    text-align: left;
    margin-bottom: 16px; }
    .footer_haut .titre2::after {
      display: block;
      content: '';
      width: 52px;
      height: 2px;
      margin: 9px auto; }
    @media screen and (max-width: 900px) {
      .footer_haut .titre2 {
        font-size: 1.25rem; }
        .footer_haut .titre2::after {
          margin: 5px auto; } }
    .footer_haut .titre2::after {
      background: #ea3c34;
      margin: 9px 0; }
  .footer_haut .presentation {
    font-style: italic;
    color: #ea3c34;
    font-size: 1.0625rem;
    margin-bottom: 13px; }
  .footer_haut .footer_newsletter, .footer_haut .footer_rs, .footer_haut .footer_presse {
    min-height: 220px;
    width: 33.3333%;
    float: left;
    padding: 53px 4% 0px 5%;
    /*60/1200*/ }
  .footer_haut .newsletter_form_box {
    width: 100%;
    /* firefox 19+ */
    /* ie */ }
    .footer_haut .newsletter_form_box::after {
      content: '';
      clear: both;
      display: table; }
    .footer_haut .newsletter_form_box .newsletter_form {
      width: 100%; }
      .footer_haut .newsletter_form_box .newsletter_form::after {
        content: '';
        clear: both;
        display: table; }
    .footer_haut .newsletter_form_box .newsletter_form_input_wrapper {
      border: 2px #ea3c34 solid; }
      .footer_haut .newsletter_form_box .newsletter_form_input_wrapper::after {
        content: '';
        clear: both;
        display: table; }
    .footer_haut .newsletter_form_box input[type=email] {
      border: none;
      font-family: "omnes-pro",sans-serif;
      font-size: 0.875rem;
      color: #ea3c34;
      padding: 0 2px;
      width: 67%;
      float: left;
      min-height: 28px;
      line-height: 28px;
      background: #f9f9f9; }
    .footer_haut .newsletter_form_box button {
      border: none;
      font-family: "omnes-pro",sans-serif;
      border-left: 2px #ea3c34 solid;
      font-size: 0.875rem;
      text-transform: uppercase;
      font-weight: 600;
      width: 33%;
      color: #ea3c34;
      float: right;
      min-height: 26px;
      line-height: 26px;
      cursor: pointer;
      background: none;
      transition: all 0.3s ease-out 0.07s; }
      .footer_haut .newsletter_form_box button:focus, .footer_haut .newsletter_form_box button:hover, .footer_haut .newsletter_form_box button:active {
        background: #ea3c34;
        color: #fff; }
    .footer_haut .newsletter_form_box ::-webkit-input-placeholder {
      color: #ea3c34;
      font-style: italic; }
    .footer_haut .newsletter_form_box ::-moz-placeholder {
      color: #ea3c34;
      font-style: italic; }
    .footer_haut .newsletter_form_box :-ms-input-placeholder {
      color: #ea3c34;
      font-style: italic; }
    .footer_haut .newsletter_form_box input:-moz-placeholder {
      color: #ea3c34;
      font-style: italic; }
  .footer_haut .rs-wrapper {
    width: 100%;
    display: table; }
  .footer_haut .rs-lien {
    display: table-cell;
    text-align: center; }
    .footer_haut .rs-lien img {
      width: 28px; }
    .footer_haut .rs-lien:hover img, .footer_haut .rs-lien:focus img, .footer_haut .rs-lien:active img {
      transform: scale(1.1); }
  .footer_haut .bouton {
    font-weight: 600;
    font-size: 0.875rem;
    text-transform: uppercase;
    border-width: 2px;
    border-style: solid;
    display: inline-block;
    padding: 10px 20px 12px;
    border-color: #ea3c34;
    color: #ea3c34;
    padding: 6px 20px 8px; }
    .footer_haut .bouton:hover, .footer_haut .bouton:focus, .footer_haut .bouton:active {
      background: #ea3c34;
      color: #fff; }

.footer_bas {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 0.875rem;
  text-transform: uppercase; }
  .footer_bas a {
    display: inline-block;
    margin: 0 10px; }
    .footer_bas a:hover, .footer_bas a:focus, .footer_bas a:active {
      text-decoration: underline; }
  .footer_bas span {
    display: inline-block;
    margin: 0 10px; }

/*---------------- MEDIA QUERIES ---------------------*/
@media screen and (max-width: 1140px) {
  .footer_haut .footer_newsletter, .footer_haut .footer_rs, .footer_haut .footer_presse {
    padding: 53px 3% 0px 3%;
    /*60/1200*/ } }

@media screen and (max-width: 920px) {
  .tropheeeco {
    padding-bottom: 50px !important; }
  .footer_haut {
    text-align: center;
    display: block;
    position: static;
    padding: 14px 0; }
    .footer_haut .footer_newsletter, .footer_haut .footer_rs, .footer_haut .footer_presse {
      padding: 14px 14px 14px 14px;
      /*60/1200*/
      width: 300px;
      display: inline-block;
      text-align: left;
      float: none;
      min-height: auto;
      vertical-align: top; }
  .footer_bas {
    position: static;
    padding: 15px 0;
    line-height: 1.5em; } }

/*Formulaire newsletter gravity temporaire*/
#gform_6 {
  font-size: 0;
  /* firefox 19+ */
  /* ie */ }
  #gform_6 input[type=email], #gform_6 input[type=text] {
    border: none;
    font-family: "omnes-pro",sans-serif;
    font-size: 0.875rem;
    color: #ea3c34;
    padding: 0 2px;
    min-height: 28px;
    line-height: 28px;
    background: #f9f9f9;
    background: none;
    width: 100%;
    border: 2px #ea3c34 solid;
    border-right: 0; }
  #gform_6 input[type=submit] {
    padding: 0;
    border: none;
    font-family: "omnes-pro",sans-serif;
    font-size: 0.875rem;
    text-transform: uppercase;
    font-weight: 600;
    color: #ea3c34;
    min-height: 26px;
    line-height: 26px;
    cursor: pointer;
    background: none;
    width: 100%;
    border: 2px #ea3c34 solid;
    transition: all 0.3s ease-out 0.07s; }
    #gform_6 input[type=submit]:focus, #gform_6 input[type=submit]:hover, #gform_6 input[type=submit]:active {
      background: #ea3c34;
      color: #fff; }
  #gform_6 ::-webkit-input-placeholder {
    color: #ea3c34;
    font-style: italic; }
  #gform_6 ::-moz-placeholder {
    color: #ea3c34;
    font-style: italic; }
  #gform_6 :-ms-input-placeholder {
    color: #ea3c34;
    font-style: italic; }
  #gform_6 input:-moz-placeholder {
    color: #ea3c34;
    font-style: italic; }
  #gform_6 .gfield_label {
    display: none; }
  #gform_6 li, #gform_6 .ginput_container, #gform_6 .gform_footer, #gform_6 ul {
    margin: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    display: block; }
  #gform_6 .gform_body {
    display: inline-block;
    width: 67%;
    vertical-align: top;
    font-size: 1rem; }
  #gform_6 .gform_footer {
    display: inline-block;
    width: 33%;
    font-size: 1rem; }
  #gform_6 .validation_error {
    font-size: 1rem; }
  #gform_6 .gform_wrapper li.gfield.gfield_error, #gform_6 .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
    margin: 0 !important; }

/*structure*/
.colgauche {
  width: 33.333%;
  /*400/1200*/
  float: left; }

.coldroite {
  width: 66.667%;
  /*800/1200*/
  float: right; }

.colgauchelarge {
  width: 66.667%;
  /*800/1200*/
  float: left; }

.coldroiteetroite {
  width: 33.333%;
  /*400/1200*/
  float: right; }

.menufixe2 {
  margin-top: 100px; }

/*blocs*/
.contenu-editable {
  line-height: 1.37em;
  font-size: 1rem; }
  .contenu-editable a {
    color: #ea3c34; }
  .contenu-editable h3 {
    margin: 2.3em 0 1em 0;
    font-weight: 600;
    font-size: 1.75rem; }
  .contenu-editable h4 {
    margin: 2.3em 0 1em 0;
    font-weight: 600;
    font-size: 1.25rem;
    text-transform: uppercase; }
  .contenu-editable p {
    margin: 1.2em 0 1.2em 0; }
  .contenu-editable ul {
    list-style-type: disc;
    margin: 1.2em 0 1.2em 0;
    padding: 0 0 0 33px; }
  .contenu-editable ol {
    list-style-type: decimal;
    margin: 1.2em 0 1.2em 0;
    padding: 0 0 0 33px; }
  .contenu-editable a:hover, .contenu-editable a:active, .contenu-editable a:focus {
    text-decoration: underline; }
  .contenu-editable sup {
    vertical-align: super; }
  .contenu-editable .wp-caption-text {
    font-style: italic; }
  .contenu-editable embed, .contenu-editable iframe, .contenu-editable object {
    max-width: 100%; }

.page_titre {
  overflow: hidden;
  background: #eee9ef;
  padding: 69px 0 78px 0; }
  .page_titre .page_titre2 {
    font-size: 3.75rem; }
  .page_titre .ariane {
    font-size: 0.875rem;
    color: #682464;
    margin: 7px 0 0 0;
    line-height: 1.2; }
    .page_titre .ariane a {
      text-decoration: underline; }
      .page_titre .ariane a:hover, .page_titre .ariane a:active, .page_titre .ariane a:focus {
        color: #ea3c34; }
    .page_titre .ariane .current {
      color: #b490b2; }
  .page_titre .ariane_chevron {
    display: inline-block;
    margin: 0 5px; }

/*partage*/
.partage {
  background: #5a225a;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.875rem;
  max-width: 398px;
  text-align: center;
  height: 44px;
  width: 100%;
  display: table;
  position: absolute;
  top: -21px; }
  .partage img {
    max-height: 18px; }
  .partage .partage_texte {
    display: table-cell;
    vertical-align: middle;
    width: 35%; }
  .partage .partage_liens {
    display: table-cell;
    vertical-align: middle;
    width: 65%; }
  .partage a {
    display: inline-block;
    margin: 0 9px; }
    .partage a:hover img, .partage a:focus img, .partage a:active img {
      transform: scale(1.1); }

/*Nouvelle discussion*/
.new_discussion {
  background: #5a225a;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.875rem;
  max-width: 398px;
  text-align: center;
  height: 44px;
  line-height: 44px;
  width: 100%;
  display: table;
  position: absolute;
  top: -21px; }
  .new_discussion img {
    margin-right: 10px;
    vertical-align: text-bottom; }
  .new_discussion:hover, .new_discussion:active, .new_discussion:focus {
    background: #ea3c34; }

/*documents liés*/
.documentslies h3 {
  margin: 2.3em 0 1em 0;
  font-weight: 600;
  font-size: 1.75rem; }

.documentslies a {
  display: block;
  padding: 20px 20px 20px 80px;
  margin-bottom: 2px;
  font-size: 1rem;
  background: #eee9ef url(../images/ico-telecharger@2x.png) no-repeat left 40px center;
  background-size: 20px; }
  .documentslies a:hover, .documentslies a:active, .documentslies a:focus {
    background-color: #e2d9e3; }

.documentslies .upper {
  text-transform: uppercase; }

/*meta date de publication*/
.meta_publication {
  font-size: 1rem;
  color: #b490b2;
  margin-top: 55px; }

/*meta date de evenement*/
.meta_evenement {
  float: left;
  color: rgba(255, 255, 255, 0.5);
  background: #5a225a;
  padding: 30px 0px 35px 0px;
  font-size: 2.25rem;
  font-weight: 600;
  margin-right: 40px;
  line-height: 1.2;
  width: 170px;
  text-align: center; }
  .meta_evenement .article {
    font-size: 1.5rem; }

/*localisation*/
.localisation h3 {
  margin: 2.3em 0 1em 0;
  font-weight: 600;
  font-size: 1.75rem; }

.localisation #canvas-carto {
  width: 100%;
  height: 527px; }

.localisation #direction label {
  font-size: 1.25rem; }

.localisation .localisation_wrapper {
  display: table;
  width: 100%;
  border: 2px #eee9ef solid;
  max-width: 400px;
  margin: 30px 0;
  /* firefox 19+ */
  /* ie */ }
  .localisation .localisation_wrapper .wrapper_input {
    width: 67%;
    float: left; }
    .localisation .localisation_wrapper .wrapper_input input {
      border: none;
      font-family: "omnes-pro",sans-serif;
      font-size: 0.875rem;
      color: #5a225a;
      padding: 0 2px 0px 5px;
      min-height: 28px;
      line-height: 28px;
      width: 100%; }
  .localisation .localisation_wrapper button {
    border-right: 0 none;
    border-top: 0 none;
    border-bottom: 0 none;
    font-family: "omnes-pro",sans-serif;
    border-left: 2px #eee9ef solid;
    font-size: 0.875rem;
    text-transform: uppercase;
    font-weight: 600;
    width: 32%;
    float: right;
    color: #5a225a;
    min-height: 26px;
    line-height: 26px;
    cursor: pointer;
    background: none;
    transition: all 0.3s ease-out 0.07s; }
    .localisation .localisation_wrapper button:focus, .localisation .localisation_wrapper button:hover, .localisation .localisation_wrapper button:active {
      background: #5a225a;
      color: #fff; }
  .localisation .localisation_wrapper ::-webkit-input-placeholder {
    color: #5a225a;
    font-style: italic; }
  .localisation .localisation_wrapper ::-moz-placeholder {
    color: #5a225a;
    font-style: italic; }
  .localisation .localisation_wrapper :-ms-input-placeholder {
    color: #5a225a;
    font-style: italic; }
  .localisation .localisation_wrapper input:-moz-placeholder {
    color: #5a225a;
    font-style: italic; }

/*filtre*/
.filtre {
  margin: 20px 0 50px 0; }
  .filtre label, .filtre .label {
    font-size: 1.125rem;
    font-weight: 600; }

/*pagination*/
.pagination {
  text-align: center;
  padding-top: 40px; }
  .pagination .screen-reader-text {
    font-size: 0; }
  .pagination .page-numbers {
    padding: 0 0 0 5px; }
    .pagination .page-numbers::after {
      content: '|';
      display: inline-block;
      margin: 0 0 0 5px;
      font-size: 1.25rem;
      font-weight: 400;
      color: #5a225a; }
    .pagination .page-numbers:last-child::after {
      content: ''; }
    .pagination .page-numbers.current {
      font-weight: 600;
      color: #b490b2; }
      .pagination .page-numbers.current:hover, .pagination .page-numbers.current:active, .pagination .page-numbers.current:focus {
        color: #b490b2; }
    .pagination .page-numbers:hover, .pagination .page-numbers:active, .pagination .page-numbers:focus {
      color: #ea3c34; }
      .pagination .page-numbers:hover:after, .pagination .page-numbers:active:after, .pagination .page-numbers:focus:after {
        color: #5a225a; }

/* PAge*/
.conteneur_principal {
  padding-bottom: 160px;
  position: relative; }
  .conteneur_principal::after {
    content: '';
    clear: both;
    display: table; }
  .conteneur_principal .coldroiteetroite {
    margin-top: -21px; }
  .conteneur_principal .colgauchelarge {
    margin-top: 25px;
    padding-right: 40px; }

.coldroiteetroite .sb_actualite, .coldroiteetroite .sb_agenda, .coldroiteetroite .sb_tweet, .coldroiteetroite .sb_rs, .coldroiteetroite .trophee, .coldroiteetroite .sb_profil, .coldroiteetroite .sb_activitesrecentes {
  margin-bottom: 40px; }

/*test sans margin bottom aux elmenets de sidebar*/
.coldroiteetroite .sb_actualite, .coldroiteetroite .sb_agenda, .coldroiteetroite .sb_tweet, .coldroiteetroite .sb_rs, .coldroiteetroite .trophee, .coldroiteetroite .sb_profil, .coldroiteetroite .sb_activitesrecentes {
  margin-bottom: 0px; }

/*agendaseul*/
.agendaseul .colgauchelarge {
  margin-top: 55px; }

/*liste agenda*/
.listeagenda_item {
  margin: 30px 0; }
  .listeagenda_item .date_evenement {
    display: table-cell;
    vertical-align: top; }
  .listeagenda_item .meta_evenement {
    float: none; }
  .listeagenda_item .texte_evenement {
    line-height: 1.2;
    display: table-cell;
    vertical-align: top; }
    .listeagenda_item .texte_evenement h3 {
      font-size: 1.75rem;
      font-weight: 600;
      margin-bottom: 0.2em; }
  .listeagenda_item .lien_evenement {
    color: #ea3c34;
    font-weight: 600;
    text-decoration: underline;
    margin: 15px 0;
    display: inline-block; }
    .listeagenda_item .lien_evenement:hover, .listeagenda_item .lien_evenement:active, .listeagenda_item .lien_evenement:focus {
      color: #ec9e00; }

/*liste actualites*/
.listeactu::after {
  content: '';
  clear: both;
  display: table; }

.listeactu_actu {
  background: #eee9ef;
  display: inline-block;
  width: 47.368%;
  /*360/760*/
  margin: 2.632% 0;
  /*20/760*/
  float: left;
  position: relative; }
  .listeactu_actu:nth-of-type(odd) {
    margin-right: 5.263%;
    /*40/760*/ }
  .listeactu_actu h3, .listeactu_actu h4 {
    line-height: 1.25;
    padding: 4px; }
  .listeactu_actu .meta_publication {
    font-weight: 600;
    font-size: 0.9375rem;
    color: rgba(90, 34, 90, 0.6);
    margin: 0;
    padding: 6px 4px; }
  .listeactu_actu .categorie {
    position: absolute;
    top: -12px;
    right: 10px;
    background: #ea3c34;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.875rem;
    padding: 5px 13px; }
  .listeactu_actu img {
    transition: all 0.3s ease-out 0.07s; }
  .listeactu_actu:hover, .listeactu_actu:active, .listeactu_actu:focus {
    background: rgba(238, 233, 239, 0.6); }
    .listeactu_actu:hover img, .listeactu_actu:active img, .listeactu_actu:focus img {
      opacity: 0.7; }

/*articles liés*/
.articles_lies h3 {
  margin: 2.3em 0 1em 0;
  font-weight: 600;
  font-size: 1.75rem; }

.articles_lies .listeactu_actu {
  width: 31.579%;
  /*240/760*/
  margin-right: 2.632%;
  /*20/760*/ }
  .articles_lies .listeactu_actu:nth-of-type(odd) {
    margin-right: 2.632%;
    /*20/760*/ }
  .articles_lies .listeactu_actu:nth-of-type(3n) {
    margin-right: 0; }

/*infos pratiques*/
.js-tabcontent[aria-hidden=true] {
  display: none; }

.infos_pratiques h3 {
  margin: 2.3em 0 1em 0;
  font-weight: 600;
  font-size: 1.75rem; }

.infos_pratiques .tabs__list {
  background: #eee9ef; }

.infos_pratiques .tabs__item {
  display: table-cell;
  height: 50px;
  line-height: 50px; }

.infos_pratiques .tabs__link {
  text-transform: uppercase;
  font-weight: 600;
  display: block;
  padding: 0 10px;
  height: 100%;
  cursor: pointer;
  position: relative;
  overflow: hidden; }
  .infos_pratiques .tabs__link::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #5a225a;
    content: '';
    transition: all 0.3s ease-out 0.07s;
    transform: translateY(-6px); }
  .infos_pratiques .tabs__link[aria-selected=true] {
    background: #fff; }
    .infos_pratiques .tabs__link[aria-selected=true]::after {
      transform: translateY(0); }
  .infos_pratiques .tabs__link:hover, .infos_pratiques .tabs__link:active, .infos_pratiques .tabs__link:focus {
    color: #b490b2;
    outline: 0; }

.infos_pratiques .invisible {
  visibility: hidden;
  font-size: 0;
  line-height: 0; }

/*inscription*/
.inscription {
  margin: 2.3em 0 1em 0;
  font-weight: 600;
  font-size: 1.75rem; }
  .inscription form {
    margin: 30px 0 0 0; }

/*mon profil*/
.profil_info {
  margin-top: 57px;
  margin-bottom: 30px; }
  .profil_info .portrait {
    width: 200px;
    float: left;
    margin-right: 30px; }
  .profil_info h3 {
    margin: 2.3em 0 1em 0;
    font-weight: 600;
    font-size: 1.75rem;
    margin-top: 0px;
    margin-bottom: 0px; }
    .profil_info h3 .nom {
      text-transform: uppercase; }
  .profil_info .champ_info {
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.25; }
  .profil_info .champ_info_societe {
    font-weight: 600; }
  .profil_info .champ_info_fonction {
    font-style: italic;
    margin-bottom: 10px; }
  .profil_info .champ_info_region {
    margin-bottom: 10px; }
  .profil_info .champ_mail {
    display: inline-block;
    font-size: 1.125rem;
    font-weight: 600;
    color: #ea3c34; }
    .profil_info .champ_mail:hover, .profil_info .champ_mail:active, .profil_info .champ_mail:focus {
      color: #ec9e00; }
  .profil_info .champ_rs {
    margin: 5px 0; }
    .profil_info .champ_rs img {
      width: 20px; }
    .profil_info .champ_rs a {
      display: inline-block;
      margin-right: 20px; }
      .profil_info .champ_rs a:hover img, .profil_info .champ_rs a:active img, .profil_info .champ_rs a:focus img {
        transform: scale(1.1); }

.btn_wrapper_profil {
  text-align: center;
  padding: 30px 0 0 0; }
  .btn_wrapper_profil .bouton {
    font-weight: 600;
    font-size: 0.875rem;
    text-transform: uppercase;
    border-width: 2px;
    border-style: solid;
    display: inline-block;
    padding: 10px 20px 12px; }
    .btn_wrapper_profil .bouton:hover, .btn_wrapper_profil .bouton:active, .btn_wrapper_profil .bouton:focus {
      background: #5a225a;
      color: #fff;
      border-color: #5a225a; }

.profil .cat_profil {
  color: #fff;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 600;
  background: #ea3c34;
  display: inline-block;
  padding: 5px 15px;
  margin-right: 5px;
  margin-bottom: 5px; }

.profil .titre4 {
  font-size: 1.25rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 25px;
  margin-top: 50px; }

.profil .list_groupe {
  padding: 0 0 0 14px; }

.profil .elmt_groupe {
  list-style-type: square;
  margin: 5px 0; }

/*extranet annuaire*/
.glossaire {
  text-transform: uppercase;
  margin-top: -20px; }
  .glossaire .glossaire_entre {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #fff;
    border-radius: 10px;
    text-align: center; }
    .glossaire .glossaire_entre:hover, .glossaire .glossaire_entre:focus, .glossaire .glossaire_entre:active {
      border-color: #5a225a; }
    .glossaire .glossaire_entre.current {
      font-weight: 600; }
      .glossaire .glossaire_entre.current:hover, .glossaire .glossaire_entre.current:focus, .glossaire .glossaire_entre.current:active {
        border-color: #fff; }

.wrapper-annuaire {
  overflow: hidden;
  padding-top: 50px;
  font-size: 0; }

.personne {
  font-size: 1rem;
  vertical-align: top;
  background: #eee9ef;
  display: inline-block;
  width: 30.263%;
  /*230/760*/
  margin-top: 2.632%;
  /*20/760*/
  margin-bottom: 2.632%;
  /*20/760*/
  margin-right: 4.605%;
  /*35/760*/
  position: relative;
  padding-bottom: 10px; }
  .personne:nth-of-type(3n) {
    margin-right: 0; }
  .personne h3 {
    line-height: 1.25;
    padding: 4px 15px 4px 15px;
    font-weight: 600; }
  .personne .champ_info {
    padding: 2px 15px 2px 15px; }
  .personne .champ_info_fonction {
    font-style: italic; }
  .personne .categorie {
    position: absolute;
    top: -12px;
    right: 10px;
    background: #ea3c34;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.875rem;
    padding: 5px 13px; }
  .personne img {
    transition: all 0.3s ease-out 0.07s; }
  .personne:hover, .personne:active, .personne:focus {
    background: rgba(238, 233, 239, 0.6); }
    .personne:hover img, .personne:active img, .personne:focus img {
      opacity: 0.7; }

/*page extranet groupe*/
.elmt_groupe {
  line-height: 1.3;
  margin: 40px 0; }
  .elmt_groupe .titre3 {
    font-weight: 600;
    font-size: 1.25rem;
    margin-bottom: 20px; }
    .elmt_groupe .titre3 a:hover, .elmt_groupe .titre3 a:active, .elmt_groupe .titre3 a:focus {
      color: #ea3c34; }
    .elmt_groupe .titre3.prive a {
      padding-left: 24px;
      background: url(../images/picto_cadenas_violet.png) no-repeat left center; }
  .elmt_groupe .separateur {
    font-weight: 400; }
  .elmt_groupe .thematique_lien {
    color: #ea3c34;
    font-weight: 400; }
  .elmt_groupe .compteur_membres img {
    vertical-align: text-bottom;
    display: inline-block;
    margin: 5px 5px 0 5px; }
  .elmt_groupe .points {
    font-size: 2.25rem; }

/*page extranet home*/
.lien_acces {
  margin-top: 100px; }
  .lien_acces .lien_public, .lien_acces .lien_restreint {
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    background-color: #ea3c34;
    display: inline-block;
    padding: 7px 20px 7px 40px;
    margin-bottom: 10px;
    background-repeat: no-repeat;
    background-position: left 10px center; }
    .lien_acces .lien_public:active, .lien_acces .lien_public:focus, .lien_acces .lien_public:hover, .lien_acces .lien_restreint:active, .lien_acces .lien_restreint:focus, .lien_acces .lien_restreint:hover {
      background-color: #5a225a; }
  .lien_acces .lien_public {
    background-image: url(../images/picto_groupe_blanc.png); }
  .lien_acces .lien_restreint {
    background-image: url(../images/picto_cadenas_blanc.png); }

.sondage {
  margin-top: 50px;
  font-weight: 600;
  background: #eee9ef;
  border: solid 3px #5a225a;
  padding: 30px 40px;
  line-height: 1.3; }
  .sondage .titre {
    font-size: 1.625rem;
    margin-bottom: 17px; }

.thematiques_home {
  font-size: 0;
  margin: 50px -20px 0 -20px; }
  .thematiques_home .titre3 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.25rem;
    margin-left: 20px; }
  .thematiques_home .thematique_item {
    width: 160px;
    height: 160px;
    display: inline-block;
    margin: 20px;
    font-size: 1.125rem;
    vertical-align: top;
    text-align: center;
    line-height: 160px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0px; }
    .thematiques_home .thematique_item h4 {
      display: inline-block;
      line-height: normal;
      vertical-align: middle; }
    .thematiques_home .thematique_item:hover, .thematiques_home .thematique_item:active, .thematiques_home .thematique_item:focus {
      opacity: 0.7; }
  .thematiques_home .conteneur_bouton_thematique {
    font-size: 1rem;
    text-align: center;
    margin-top: 20px; }
    .thematiques_home .conteneur_bouton_thematique .bouton {
      display: inline-block;
      background: #5a225a;
      color: #fff;
      font-weight: 600;
      text-transform: uppercase;
      border: 2px solid #5a225a;
      padding: 6px 15px; }
      .thematiques_home .conteneur_bouton_thematique .bouton:hover, .thematiques_home .conteneur_bouton_thematique .bouton:active, .thematiques_home .conteneur_bouton_thematique .bouton:focus {
        background: #fff;
        color: #5a225a; }

.liste_groupe_thematique {
  margin-top: 50px; }
  .liste_groupe_thematique h3 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.25rem; }
  .liste_groupe_thematique .titre3 {
    font-size: 1.125rem;
    text-transform: none; }
  .liste_groupe_thematique .conteneur_bouton {
    font-size: 1rem;
    text-align: center;
    margin-top: 20px; }
    .liste_groupe_thematique .conteneur_bouton .bouton {
      display: inline-block;
      background: #5a225a;
      color: #fff;
      font-weight: 600;
      text-transform: uppercase;
      border: 2px solid #5a225a;
      padding: 6px 15px; }
      .liste_groupe_thematique .conteneur_bouton .bouton:hover, .liste_groupe_thematique .conteneur_bouton .bouton:active, .liste_groupe_thematique .conteneur_bouton .bouton:focus {
        background: #fff;
        color: #5a225a; }

.contenu_thematiques {
  margin-top: 50px; }

/*page liste_thematique*/
.elmt_thematique {
  line-height: 1.3;
  margin: 32px 0; }
  .elmt_thematique .tn3 a {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.25rem;
    margin-bottom: 20px;
    background-image: url(../images/picto_entre_violet.png);
    background-repeat: no-repeat;
    display: inline-block;
    padding-right: 30px;
    background-position: right center; }
    .elmt_thematique .tn3 a:hover, .elmt_thematique .tn3 a:active, .elmt_thematique .tn3 a:focus {
      color: #ea3c34; }
  .elmt_thematique .presentation {
    font-size: 1.125rem; }
  .elmt_thematique .last_discussion {
    color: #ea3c34;
    font-weight: 600; }
    .elmt_thematique .last_discussion a:hover, .elmt_thematique .last_discussion a:focus, .elmt_thematique .last_discussion a:active {
      text-decoration: underline; }
  .elmt_thematique.prive .tn3 a {
    background-image: url(../images/picto_entre_violet.png), url(../images/picto_cadenas_violet.png);
    background-repeat: no-repeat;
    padding-right: 65px;
    background-position: right center, right 30px center; }

/*-------accueil--------*/
.actuagenda {
  padding-bottom: 55px;
  position: relative; }
  .actuagenda::after {
    content: '';
    clear: both;
    display: table; }

.sidebar {
  margin-top: -21px; }

.sidebar--mob {
  display: none; }

.actualites {
  margin: 54px 1.25% 0;
  text-align: center; }
  .actualites .titre2 {
    margin-bottom: 20px;
    font-size: 1.625rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.04em;
    text-align: center;
    margin-bottom: 0; }
    .actualites .titre2::after {
      display: block;
      content: '';
      width: 52px;
      height: 2px;
      margin: 9px auto; }
    @media screen and (max-width: 900px) {
      .actualites .titre2 {
        font-size: 1.25rem; }
        .actualites .titre2::after {
          margin: 5px auto; } }
    .actualites .titre2::after {
      background: #5a225a; }
  .actualites .actualites_wrapper {
    display: table;
    border-spacing: 20px;
    table-layout: fixed; }
  .actualites .actualites_article {
    width: 43.589%;
    /*340/780*/
    padding: 10px;
    display: table-cell; }
    .actualites .actualites_article img {
      width: 100%; }
    .actualites .actualites_article:focus, .actualites .actualites_article:hover, .actualites .actualites_article:active {
      background: #eee9ef; }
  .actualites .article_date {
    font-size: 1rem;
    margin: 13px 0; }
  .actualites .article_titre {
    font-weight: 600;
    font-size: 1.25rem;
    margin: 10px 0;
    line-height: 1.15; }
  .actualites .article_extrait {
    font-size: 1rem;
    text-align: left;
    margin: 10px 0;
    line-height: 1.2; }
  .actualites .bouton {
    font-weight: 600;
    font-size: 0.875rem;
    text-transform: uppercase;
    border-width: 2px;
    border-style: solid;
    display: inline-block;
    padding: 10px 20px 12px;
    border-color: #5a225a;
    margin-top: -5px; }
    .actualites .bouton:hover, .actualites .bouton:focus, .actualites .bouton:active {
      background: #5a225a;
      color: #fff; }

.veille {
  text-align: center;
  margin: 54px 2.875% 0;
  /*23/800*/ }
  .veille .titre2 {
    margin-bottom: 0;
    margin-bottom: 20px;
    font-size: 1.625rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.04em;
    text-align: center; }
    .veille .titre2::after {
      display: block;
      content: '';
      width: 52px;
      height: 2px;
      margin: 9px auto; }
    @media screen and (max-width: 900px) {
      .veille .titre2 {
        font-size: 1.25rem; }
        .veille .titre2::after {
          margin: 5px auto; } }
    .veille .titre2 span {
      text-transform: none; }
    .veille .titre2::after {
      background: #5a225a; }
  .veille .veille_wrapper {
    display: table;
    border-spacing: 7px; }
  .veille .veille_article {
    width: 32.095%;
    /*242/754*/
    display: table-cell;
    padding: 10px; }
    .veille .veille_article:focus, .veille .veille_article:hover, .veille .veille_article:active {
      background: #eee9ef; }
    .veille .veille_article img {
      width: 100%; }
  .veille .article_date {
    font-size: 1rem;
    margin: 13px 0; }
  .veille .article_titre {
    font-weight: 600;
    font-size: 1.125rem;
    margin: 10px 0;
    line-height: 1.15; }
  .veille .bouton {
    font-weight: 600;
    font-size: 0.875rem;
    text-transform: uppercase;
    border-width: 2px;
    border-style: solid;
    display: inline-block;
    padding: 10px 20px 12px;
    border-color: #5a225a;
    margin-top: 8px; }
    .veille .bouton:hover, .veille .bouton:focus, .veille .bouton:active {
      background: #5a225a;
      color: #fff; }

.reseau {
  background: #ec9e00;
  text-align: center;
  padding: 55px 0;
  color: #fff; }
  .reseau .titre2 {
    margin-bottom: 20px;
    font-size: 1.625rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.04em;
    text-align: center; }
    .reseau .titre2::after {
      display: block;
      content: '';
      width: 52px;
      height: 2px;
      margin: 9px auto; }
    @media screen and (max-width: 900px) {
      .reseau .titre2 {
        font-size: 1.25rem; }
        .reseau .titre2::after {
          margin: 5px auto; } }
    .reseau .titre2::after {
      background: #fff; }
  .reseau .reseau-presentation {
    font-style: italic;
    font-size: 1.25rem;
    line-height: 1.2;
    padding: 20px 60px; }
  .reseau h3 {
    font-size: 1.5rem;
    font-style: italic;
    font-weight: 600;
    margin-top: 15px; }
  .reseau .bouton {
    font-weight: 600;
    font-size: 0.875rem;
    text-transform: uppercase;
    border-width: 2px;
    border-style: solid;
    display: inline-block;
    padding: 10px 20px 12px;
    border-color: #fff;
    margin: 9px; }
    .reseau .bouton:hover, .reseau .bouton:active, .reseau .bouton:focus {
      color: #ec9e00;
      background: #fff; }

/*carousel*/
.reseau_carousel {
  width: 88.333% !important;
  /*1060/1200*/
  margin: 20px 5.83333% 40px 5.83333%;
  /*70*/
  height: 100px;
  background: #fff; }
  .reseau_carousel .membres {
    height: 100px;
    background: #fff;
    line-height: 100px; }
    .reseau_carousel .membres img {
      vertical-align: middle;
      display: inline-block !important;
      width: auto !important;
      max-width: 85%;
      max-height: 80px; }
  .reseau_carousel .owl-prev {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-image: url(../images/reseau-carousel-precedent@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: 12px;
    height: 24px;
    font-size: 0;
    left: -40px; }
  .reseau_carousel .owl-next {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-image: url(../images/reseau-carousel-suivant@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
    font-size: 0;
    width: 12px;
    height: 24px;
    right: -40px; }

.tropheeeco {
  background: #eee9ef;
  padding: 50px 0 160px 0; }
  .tropheeeco::after {
    content: '';
    clear: both;
    display: table; }

.ecosysteme {
  padding: 5px 0 0 0;
  text-align: center; }
  .ecosysteme .titre2 {
    margin-bottom: 20px;
    font-size: 1.625rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.04em;
    text-align: center;
    margin-bottom: 40px; }
    .ecosysteme .titre2::after {
      display: block;
      content: '';
      width: 52px;
      height: 2px;
      margin: 9px auto; }
    @media screen and (max-width: 900px) {
      .ecosysteme .titre2 {
        font-size: 1.25rem; }
        .ecosysteme .titre2::after {
          margin: 5px auto; } }
    .ecosysteme .titre2::after {
      background-color: #5a225a; }

@media screen and (max-width: 1240px) {
  .actuagenda.centre, .tropheeeco .centre {
    margin: 0 14px; } }

@media screen and (max-width: 920px) {
  .actuagenda.centre {
    margin: 0 0px;
    padding-bottom: 0; }
  .actuagenda .coldroite {
    float: none;
    width: auto;
    margin: 0 auto; }
  .sidebar {
    display: none; }
  .sidebar--mob {
    display: block;
    margin: 54px auto 0 auto; }
  .reseau .reseau_carousel .owl-prev {
    left: -20px; }
  .reseau .reseau_carousel .owl-next {
    right: -20px; }
  .trophee {
    padding-bottom: 100px; }
  .tropheeeco .colgauche {
    float: none;
    width: auto; }
  .tropheeeco .coldroite {
    float: none;
    width: auto; }
  .trophee {
    padding-bottom: 50px;
    margin-bottom: 50px; }
    .trophee .trophee_img {
      position: static;
      transform: none;
      max-width: 100%;
      margin: 0 auto; } }

@media screen and (max-width: 650px) {
  .actuagenda.centre {
    margin: 0 14px; }
  .actualites {
    margin: 54px 0 0 0; }
  .reseau {
    margin: 0 14px; }
    .reseau .reseau_carousel .owl-prev {
      left: -20px; }
    .reseau .reseau_carousel .owl-next {
      right: -20px; } }

@media screen and (max-width: 550px) {
  .actualites {
    margin: 54px 0 0 0; }
    .actualites .actualites_wrapper {
      display: block; }
    .actualites .actualites_article {
      display: block;
      width: auto;
      margin: 10px 0; }
  .veille {
    margin: 54px 0 0 0; }
    .veille .veille_wrapper {
      display: block; }
    .veille .veille_article {
      display: block;
      width: auto;
      margin: 10px 0; } }

@media screen and (max-width: 400px) {
  .reseau {
    margin: 0 14px; }
    .reseau .reseau_carousel {
      width: 80% !important;
      margin-right: auto;
      margin-left: auto; } }

/* Sidebar*/
.sb_actualite {
  background: #fff;
  color: #5a225a;
  border: solid 2px #eee9ef;
  text-align: center;
  padding: 60px 8.75%;
  /*35/400*/ }
  .sb_actualite .titre2 {
    margin-bottom: 20px;
    font-size: 1.625rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.04em;
    text-align: center; }
    .sb_actualite .titre2::after {
      display: block;
      content: '';
      width: 52px;
      height: 2px;
      margin: 9px auto; }
    @media screen and (max-width: 900px) {
      .sb_actualite .titre2 {
        font-size: 1.25rem; }
        .sb_actualite .titre2::after {
          margin: 5px auto; } }
    .sb_actualite .titre2::after {
      background: #5a225a; }
  .sb_actualite .sbactu_article {
    display: table;
    width: 100%;
    text-align: left;
    padding: 10px;
    margin: 10px 0; }
    .sb_actualite .sbactu_article:focus, .sb_actualite .sbactu_article:active, .sb_actualite .sbactu_article:hover {
      background: #eee9ef; }
  .sb_actualite .sbactu_img {
    display: table-cell;
    width: 37%;
    padding-right: 10px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.28);
    font-size: 2rem; }
  .sb_actualite .sbactu_titre {
    display: table-cell;
    font-weight: 600;
    vertical-align: top;
    line-height: 1.2; }
  .sb_actualite .meta_publication {
    margin: 5px 0 0 0;
    font-size: 0.75rem;
    color: #b490b2;
    font-weight: 400; }
  .sb_actualite .bouton {
    margin-top: 20px;
    font-weight: 600;
    font-size: 0.875rem;
    text-transform: uppercase;
    border-width: 2px;
    border-style: solid;
    display: inline-block;
    padding: 10px 20px 12px;
    color: #5a225a;
    border-color: #5a225a; }
    .sb_actualite .bouton:focus, .sb_actualite .bouton:active, .sb_actualite .bouton:hover {
      background: #5a225a;
      color: #fff; }

.sb_agenda {
  background: #5a225a;
  color: #fff;
  text-align: center;
  padding: 60px 8.75%;
  /*35/400*/ }
  .sb_agenda .titre2 {
    margin-bottom: 20px;
    font-size: 1.625rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.04em;
    text-align: center; }
    .sb_agenda .titre2::after {
      display: block;
      content: '';
      width: 52px;
      height: 2px;
      margin: 9px auto; }
    @media screen and (max-width: 900px) {
      .sb_agenda .titre2 {
        font-size: 1.25rem; }
        .sb_agenda .titre2::after {
          margin: 5px auto; } }
    .sb_agenda .titre2::after {
      background: #fff; }
  .sb_agenda .agenda_event {
    display: table;
    width: 100%;
    text-align: left;
    padding: 10px;
    margin: 10px 0; }
    .sb_agenda .agenda_event:focus, .sb_agenda .agenda_event:active, .sb_agenda .agenda_event:hover {
      background: rgba(255, 255, 255, 0.28); }
  .sb_agenda .event_date {
    display: table-cell;
    width: 37%;
    /*102/330*/
    font-weight: 600;
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.625rem; }
    .sb_agenda .event_date .article {
      font-size: 1rem; }
  .sb_agenda .event_title {
    display: table-cell;
    vertical-align: top;
    line-height: 1.2; }
  .sb_agenda .bouton {
    margin-top: 20px;
    font-weight: 600;
    font-size: 0.875rem;
    text-transform: uppercase;
    border-width: 2px;
    border-style: solid;
    display: inline-block;
    padding: 10px 20px 12px;
    color: #fff;
    border-color: #fff; }
    .sb_agenda .bouton:focus, .sb_agenda .bouton:active, .sb_agenda .bouton:hover {
      background: #fff;
      color: #5a225a; }

.sb_tweet {
  background: #ea3c34;
  padding: 55px 8.75%;
  /*35/400*/
  color: #fff; }
  .sb_tweet .titre2 {
    margin-bottom: 20px;
    font-size: 1.625rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.04em;
    text-align: center; }
    .sb_tweet .titre2::after {
      display: block;
      content: '';
      width: 52px;
      height: 2px;
      margin: 9px auto; }
    @media screen and (max-width: 900px) {
      .sb_tweet .titre2 {
        font-size: 1.25rem; }
        .sb_tweet .titre2::after {
          margin: 5px auto; } }
    .sb_tweet .titre2:after {
      background-color: #fff; }
  .sb_tweet .tweet_wrapper {
    margin-top: 25px; }
  .sb_tweet .tweet_logo {
    display: table-cell;
    width: 20.303%;
    /*67/330*/
    padding-right: 10px; }
  .sb_tweet .tweet_content {
    display: table-cell;
    vertical-align: top;
    font-size: 1.125rem;
    line-height: 1.2; }
    .sb_tweet .tweet_content a {
      font-weight: 600; }
      .sb_tweet .tweet_content a:hover, .sb_tweet .tweet_content a:active, .sb_tweet .tweet_content a:focus {
        color: #5a225a; }
    .sb_tweet .tweet_content .tweet_metadonnees {
      font-size: 0.75rem;
      display: block;
      margin-bottom: 10px; }
      .sb_tweet .tweet_content .tweet_metadonnees a {
        font-weight: 400; }

.sb_rs {
  background: #d43b34;
  padding: 55px 8.75%;
  /*35/400*/
  color: #fff; }
  .sb_rs .titre2 {
    margin-bottom: 20px;
    font-size: 1.625rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.04em;
    text-align: center; }
    .sb_rs .titre2::after {
      display: block;
      content: '';
      width: 52px;
      height: 2px;
      margin: 9px auto; }
    @media screen and (max-width: 900px) {
      .sb_rs .titre2 {
        font-size: 1.25rem; }
        .sb_rs .titre2::after {
          margin: 5px auto; } }
    .sb_rs .titre2::after {
      background: #fff; }
  .sb_rs .rs-wrapper {
    width: 100%;
    display: table; }
  .sb_rs .rs-lien {
    display: table-cell;
    text-align: center; }
    .sb_rs .rs-lien img {
      width: 30px; }
    .sb_rs .rs-lien:hover img, .sb_rs .rs-lien:focus img, .sb_rs .rs-lien:active img {
      transform: scale(1.1); }

.sb_trophee {
  background: #e2d9e3;
  text-align: center;
  padding: 55px 14px 135px;
  position: relative; }
  .sb_trophee .titre2 {
    margin-bottom: 20px;
    font-size: 1.625rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.04em;
    text-align: center; }
    .sb_trophee .titre2::after {
      display: block;
      content: '';
      width: 52px;
      height: 2px;
      margin: 9px auto; }
    @media screen and (max-width: 900px) {
      .sb_trophee .titre2 {
        font-size: 1.25rem; }
        .sb_trophee .titre2::after {
          margin: 5px auto; } }
    .sb_trophee .titre2::after {
      background: #5a225a; }
  .sb_trophee .trophee_presentation {
    font-size: 1.25rem;
    font-style: italic;
    margin: 20px 0;
    padding: 0 14px; }
  .sb_trophee .bouton {
    font-weight: 600;
    font-size: 0.875rem;
    text-transform: uppercase;
    border-width: 2px;
    border-style: solid;
    display: inline-block;
    padding: 10px 20px 12px;
    margin: 15px 0 30px 0;
    border-color: #5a225a; }
    .sb_trophee .bouton:focus, .sb_trophee .bouton:active, .sb_trophee .bouton:hover {
      background: #5a225a;
      color: #e2d9e3; }
  .sb_trophee .trophee_img {
    position: absolute;
    bottom: 0;
    display: block;
    max-width: 106.25%;
    left: 50%;
    transform: translateX(-50%); }

.sb_profil {
  background: #fff;
  color: #5a225a;
  border: solid 2px #eee9ef;
  padding: 60px 8.75%;
  /*35/400*/
  font-weight: 600; }
  .sb_profil .header_sbprofil {
    overflow: hidden; }
  .sb_profil .portrait {
    display: block;
    float: left; }
    .sb_profil .portrait img {
      width: 50px;
      margin-right: 13px; }
  .sb_profil .titre {
    font-size: 1.125rem;
    margin-bottom: 4px; }
  .sb_profil .deconnecter {
    color: #ea3c34; }
    .sb_profil .deconnecter:hover, .sb_profil .deconnecter:active, .sb_profil .deconnecter:focus {
      color: #ec9e00; }
  .sb_profil .croix {
    display: inline-block;
    margin-right: 10px;
    text-transform: capitalize; }
  .sb_profil .navigation {
    margin-top: 10px; }
  .sb_profil .nav_elemt {
    background-image: url(../images/profil_liste_barre@2x.png);
    background-repeat: no-repeat;
    background-position: left center;
    display: inline-block;
    padding: 5px 0 5px 35px;
    background-size: 20px;
    margin: 5px 0 0 0; }
    .sb_profil .nav_elemt:active, .sb_profil .nav_elemt:focus, .sb_profil .nav_elemt:hover {
      text-decoration: underline; }
  .sb_profil .current {
    background-image: url(../images/profil_liste_point@2x.png); }

.extranet .sb_actualite {
  color: #fff;
  background: #5a225a;
  border: none; }
  .extranet .sb_actualite .titre2::after {
    background: #fff; }
  .extranet .sb_actualite .sbactu_article:focus, .extranet .sb_actualite .sbactu_article:active, .extranet .sb_actualite .sbactu_article:hover {
    background: rgba(255, 255, 255, 0.28); }
  .extranet .sb_actualite .bouton {
    color: #fff;
    border-color: #fff; }
    .extranet .sb_actualite .bouton:focus, .extranet .sb_actualite .bouton:active, .extranet .sb_actualite .bouton:hover {
      background: #fff;
      color: #5a225a; }

.sb_activitesrecentes {
  background: #ea3c34;
  padding: 55px 8.75%;
  /*35/400*/
  color: #fff; }
  .sb_activitesrecentes .titre2 {
    margin-bottom: 20px;
    font-size: 1.625rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.04em;
    text-align: center; }
    .sb_activitesrecentes .titre2::after {
      display: block;
      content: '';
      width: 52px;
      height: 2px;
      margin: 9px auto; }
    @media screen and (max-width: 900px) {
      .sb_activitesrecentes .titre2 {
        font-size: 1.25rem; }
        .sb_activitesrecentes .titre2::after {
          margin: 5px auto; } }
    .sb_activitesrecentes .titre2:after {
      background-color: #fff; }
  .sb_activitesrecentes .activite {
    display: block;
    text-align: left;
    padding: 10px;
    margin: 10px 0;
    font-weight: 400; }
    .sb_activitesrecentes .activite:focus, .sb_activitesrecentes .activite:active, .sb_activitesrecentes .activite:hover {
      background: rgba(255, 255, 255, 0.28); }
  .sb_activitesrecentes .fil {
    font-style: italic; }

@media screen and (max-width: 1140px) {
  .sb_actualite .sbactu_titre {
    font-size: 0.875rem; }
  .sb_agenda .event_date {
    font-size: 1.25rem; }
  .sb_agenda .event_title {
    font-size: 0.875rem; }
  .sb_tweet .tweet_content {
    font-size: 0.875rem; }
  .sb_trophee {
    padding-bottom: 114px; }
  .sb_activitesrecentes .activite {
    font-size: 0.875rem; } }

@media screen and (max-width: 920px) {
  .sb_agenda {
    display: table-cell;
    width: 50%; }
    .sb_agenda .event_date {
      font-size: 1.0625rem; }
  .sb_wrapper--tweetrs {
    display: table-cell;
    width: 50%;
    background: #d43b34; }
  .sb_trophee {
    padding-bottom: 100px; }
  .sb_trophee {
    padding-bottom: 50px;
    margin-bottom: 50px; }
    .sb_trophee .trophee_img {
      position: static;
      transform: none;
      max-width: 100%;
      margin: 0 auto; } }

@media screen and (max-width: 650px) {
  .sb_agenda {
    display: block;
    width: auto; }
    .sb_agenda .event_date {
      width: 20%; }
  .sb_actualite {
    display: block;
    width: auto; }
    .sb_actualite .sbactu_img {
      width: 20%;
      padding-right: 20px; }
  .sb_wrapper--tweetrs {
    display: block;
    background: none;
    width: auto; } }

@media screen and (max-width: 400px) {
  .sb_actualite .sbactu_img {
    width: 40%; }
  .sb_agenda .event_date {
    width: 40%; }
  .sb_rs .rs-lien img {
    width: 20px; } }

/*formulaires*/
.contenu-editable form ul {
  list-style-type: none;
  padding: 0; }

.gform_body .gfield {
  margin: 10px 0; }
  .gform_body .gfield .gfield_label {
    text-transform: uppercase; }
  .gform_body .gfield input[type="text"], .gform_body .gfield input[type="email"], .gform_body .gfield input[type="tel"], .gform_body .gfield input[type="file"] {
    display: block;
    border: 2px solid #eee9ef;
    padding: 5px 0px 5px 10px;
    font-family: "omnes-pro",sans-serif;
    color: #5a225a;
    font-size: 0.875rem; }
    .gform_body .gfield input[type="text"]::placeholder, .gform_body .gfield input[type="email"]::placeholder, .gform_body .gfield input[type="tel"]::placeholder, .gform_body .gfield input[type="file"]::placeholder {
      font-style: italic;
      color: #5a225a; }
  .gform_body .gfield input.large {
    width: 100%; }
  .gform_body .gfield textarea {
    display: block;
    border: 2px solid #eee9ef;
    font-family: "omnes-pro",sans-serif;
    color: #5a225a;
    font-size: 0.875rem;
    width: 100%;
    padding: 5px 0px 0px 10px;
    min-height: 101px; }
    .gform_body .gfield textarea::placeholder {
      font-style: italic;
      color: #5a225a; }

.gform_body .checkbox-label {
  display: inline-block;
  font-size: 1rem;
  font-weight: normal; }
  .gform_body .checkbox-label a {
    text-decoration: underline; }
    .gform_body .checkbox-label a:hover, .gform_body .checkbox-label a:focus, .gform_body .checkbox-label a:active {
      color: #ea3c34;
      text-decoration: none; }

.gform_footer .button {
  cursor: pointer;
  padding: 2px 35px;
  font-family: "omnes-pro",sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background: #5a225a;
  border: solid 2px #5a225a;
  text-transform: uppercase;
  transition: all 0.3s ease-out 0.07s; }
  .gform_footer .button:hover, .gform_footer .button:active, .gform_footer .button:focus {
    background: #fff;
    color: #5a225a; }

.gform_validation_error {
  font-size: 1.2em; }

.gform_confirmation_message {
  font-size: 1.2em; }

/*select*/
.ui-selectmenu-button {
  border: solid 2px #eee9ef;
  padding: 2px;
  font-style: italic;
  color: #b490b2;
  margin: 0 10px; }
  .ui-selectmenu-button:hover, .ui-selectmenu-button:active, .ui-selectmenu-button:focus {
    border-color: #e2d9e3; }

.ui-selectmenu-icon {
  background-image: url(../images/select_icon.png);
  background-position: center center;
  width: 28px;
  height: 28px; }

.ui-widget-content {
  border: solid 2px #eee9ef;
  border-top: none; }
  .ui-widget-content .ui-menu-item {
    padding: 5px 0;
    background: #fff; }
    .ui-widget-content .ui-menu-item:hover, .ui-widget-content .ui-menu-item:active, .ui-widget-content .ui-menu-item:focus {
      background: #eee9ef; }

.filtre {
  vertical-align: top; }
  .filtre .ui-selectmenu-button {
    width: 10em; }
  .filtre .wrapper_filtre_recherche {
    display: inline-block;
    vertical-align: bottom; }
  .filtre .filtre_input {
    vertical-align: bottom;
    border: solid 2px #eee9ef;
    padding: 2px 2px 2px 28px;
    font-style: italic;
    color: #b490b2;
    margin: 0 10px;
    font-family: "omnes-pro",sans-serif;
    font-size: 1rem;
    line-height: 24px;
    height: 28px;
    background: url(../images/filtre_loupe@2x.png) no-repeat 2px center;
    background-size: 16px; }
    .filtre .filtre_input:hover, .filtre .filtre_input:active, .filtre .filtre_input:focus {
      border-color: #e2d9e3; }
    .filtre .filtre_input::placeholder {
      opacity: 1; }
  .filtre .filtre_button {
    border: none;
    vertical-align: bottom;
    padding: 0;
    background: 0;
    cursor: pointer;
    height: 36px; }
    .filtre .filtre_button img {
      width: 36px; }
    .filtre .filtre_button:hover, .filtre .filtre_button:active, .filtre .filtre_button:focus {
      opacity: 0.7; }

/*formulaire de connexion*/
#loginform p {
  margin: 10px 0; }
  #loginform p label {
    text-transform: uppercase;
    display: inline-block;
    margin: 20px 0 10px; }
  #loginform p .input {
    display: block;
    border: 2px solid #eee9ef;
    padding: 5px 0px 5px 10px;
    font-family: "omnes-pro",sans-serif;
    color: #5a225a;
    font-size: 0.875rem;
    width: 100%;
    max-width: 300px; }

.login-submit #wp-submit {
  cursor: pointer;
  padding: 2px 35px;
  font-family: "omnes-pro",sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background: #5a225a;
  border: solid 2px #5a225a;
  text-transform: uppercase;
  transition: all 0.3s ease-out 0.07s; }
  .login-submit #wp-submit:hover, .login-submit #wp-submit:active, .login-submit #wp-submit:focus {
    background: #fff;
    color: #5a225a; }

/*Commentaires*/
.comments-area {
  margin-top: 40px; }
  .comments-area a:hover, .comments-area a:active, .comments-area a:focus {
    text-decoration: underline; }
  .comments-area .comment {
    padding-left: 10px;
    border-left: 1px solid #eee9ef; }
  .comments-area .children {
    padding-left: 10px; }
  .comments-area .comment-author img {
    display: inline-block;
    vertical-align: middle; }
  .comments-area .comment-author .fn {
    display: inline-block;
    font-weight: 600;
    margin: 0 5px; }
  .comments-area .comment-author .says {
    display: inline-block; }
  .comments-area .comment-meta {
    color: #b490b2;
    margin: 4px 0 15px 0;
    font-size: 0.875rem; }
  .comments-area .reply {
    margin: 15px 0 40px 0; }
  .comments-area .comment-reply-link {
    color: #ea3c34;
    font-size: 0.875rem; }
  .comments-area .comment-reply-title, .comments-area .comments-title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.25rem;
    margin: 1.3em 0; }
  .comments-area .logged-in-as {
    margin: 1.3em 0; }
    .comments-area .logged-in-as a:last-of-type {
      color: #ea3c34; }
  .comments-area .comment-respond {
    margin-top: 50px; }

.comment-form label {
  color: #b490b2;
  margin-bottom: 10px;
  display: inline-block; }

.comment-form textarea {
  display: block;
  border: 2px solid #eee9ef;
  font-family: "omnes-pro",sans-serif;
  color: #5a225a;
  font-size: 0.875rem;
  width: 100%;
  padding: 5px 0px 0px 10px;
  min-height: 101px; }
  .comment-form textarea::placeholder {
    font-style: italic;
    color: #5a225a; }

.comment-form input.submit {
  margin-top: 30px;
  cursor: pointer;
  padding: 2px 35px;
  font-family: "omnes-pro",sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background: #5a225a;
  border: solid 2px #5a225a;
  text-transform: uppercase;
  transition: all 0.3s ease-out 0.07s; }
  .comment-form input.submit:hover, .comment-form input.submit:active, .comment-form input.submit:focus {
    background: #fff;
    color: #5a225a; }

/*fenêtre de partage*/
#modalOverlay {
  width: 100%;
  height: 100%;
  z-index: 2;
  /* on place le calque au dessus du contenu et en dessous de la fenÃªtre modale */
  background-color: #000;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  margin: 0;
  padding: 0; }

#modal {
  text-align: center;
  width: 400px;
  margin-left: -200px;
  padding: 5px;
  border: 3px #fff solid;
  background-color: #fff;
  z-index: 3;
  /* On place la fenÃªtre modale au dessus de tout le reste */
  position: fixed;
  top: 25%;
  left: 50%;
  display: none; }

#modalTitle {
  margin: 1.3em 0;
  font-weight: 600;
  font-size: 1.75rem;
  text-align: center;
  line-height: 1.3; }

.modalCloseButton {
  float: right;
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 1.75rem;
  color: #5a225a;
  font-weight: 600; }

.screen-reader-offscreen {
  position: absolute;
  left: -999px;
  width: 1px;
  height: 1px;
  top: auto; }

#modal input[type=text], #modal input[type=email] {
  margin: 0 auto;
  display: block;
  border: 2px solid #eee9ef;
  padding: 5px 0px 5px 10px;
  font-family: "omnes-pro",sans-serif;
  color: #5a225a;
  font-size: 0.875rem;
  width: 70%; }

#modal label {
  text-transform: uppercase; }

input[type=submit] {
  margin-top: 30px;
  cursor: pointer;
  padding: 2px 35px;
  font-family: "omnes-pro",sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background: #5a225a;
  border: solid 2px #5a225a;
  text-transform: uppercase;
  transition: all 0.3s ease-out 0.07s; }
  input[type=submit]:hover, input[type=submit]:active, input[type=submit]:focus {
    background: #fff;
    color: #5a225a; }

/*mobiles*/
@media screen and (max-width: 1240px) {
  .conteneur_principal {
    margin: 0 auto;
    max-width: 1200px; }
    .conteneur_principal .centre {
      margin: 0 14px; }
    .conteneur_principal .partage, .conteneur_principal .new_discussion {
      left: 0; }
  .page_titre .centre {
    max-width: auto;
    margin: 0 14px; }
  .partage, .new_discussion {
    width: 34.821%; }
    .partage a, .new_discussion a {
      margin: 0 8px; } }

@media screen and (max-width: 1015px) {
  .page_titre {
    padding: 30px 0; }
    .page_titre .page_titre2 {
      font-size: 2.25rem; }
  .partage {
    display: none; }
  .new_discussion {
    position: absolute;
    top: -90px;
    min-width: 300px; }
  .conteneur_principal .colgauchelarge {
    width: auto;
    float: none;
    padding-right: 0;
    max-width: 760px;
    margin-right: auto;
    margin-left: auto; }
  .conteneur_principal .coldroiteetroite {
    width: auto;
    float: none;
    margin-top: 40px;
    max-width: 440px;
    margin-left: auto;
    margin-right: auto; }
    .conteneur_principal .coldroiteetroite .sb_agenda {
      display: block;
      width: auto; }
  .conteneur_principal .sb_actualite, .conteneur_principal .sb_agenda, .conteneur_principal .sb_tweet, .conteneur_principal .sb_rs, .conteneur_principal .trophee {
    margin-bottom: 0px; }
  .contenu-editable h3 {
    font-size: 1.5rem; }
  .contenu-editable h4 {
    font-size: 1rem; }
  .meta_publication {
    font-size: 1rem;
    margin: 35px 0 0 0; }
  .meta_evenement {
    padding: 30px 0px 35px 0px;
    font-size: 1.5rem;
    margin-right: 30px;
    width: 130px; }
    .meta_evenement .article {
      font-size: 1rem; }
  .listeagenda_item .date_evenement {
    display: block;
    float: left; }
  .listeagenda_item .texte_evenement {
    display: block; }
  .listeactu {
    text-align: center; }
  .listeactu_actu {
    float: none;
    width: 272px;
    margin: 15px 10px;
    text-align: left; }
  .filtre {
    line-height: 1.2;
    margin: 20px 0; }
  .pagination {
    line-height: 1.2;
    padding-top: 20px; }
  .infos_pratiques .tabs__list {
    text-align: center;
    font-size: 0; }
  .infos_pratiques .tabs__item {
    font-size: 1rem;
    display: inline-block; }
  .glossaire {
    margin-top: 20px; }
  .wrapper-annuaire {
    text-align: center; }
  .personne {
    float: none;
    width: 230px;
    margin: 12px 12px;
    text-align: left; }
  .extranet .filtre .ui-selectmenu-button, .extranet .filtre .filtre_input, .extranet .filtre .filtre_button {
    margin-bottom: 10px; }
  .extranet .filtre .filtre_input {
    margin-left: 0; }
  .thematiques_home {
    text-align: center;
    margin: 50px 0; }
    .thematiques_home .titre3 {
      margin: 0;
      text-align: left; }
  .contenu-editable.contenu_thematiques {
    margin-top: 100px; } }

@media screen and (max-width: 920px) {
  .conteneur_principal {
    padding-bottom: 0; }
  .profil_info .portrait {
    float: none;
    margin-bottom: 10px; } }

/*Css pour impression*/
@media print {
  .noprint {
    display: none; }
  body {
    /* Modifications : la couleur de fond de page - la police - l'unité utilisée pour la taille de la police  */
    background-color: #fff;
    font-size: 12pt; }
  .topheader_nav, .menu, .partage, .sb_actualite, .sb_agenda, .sb_tweet, .sb_rs, .sb_trophee, footer, #direction {
    display: none; }
  .contenu-editable a[href]:after {
    content: " (" attr(href) ")";
    /* affichage des URL des liens */
    word-wrap: break-word; }
  .topheader_logo {
    position: static;
    text-align: center; }
  .page_titre {
    padding: 30px 0; }
  .meta_evenement {
    -webkit-print-color-adjust: exact; }
  .conteneur_principal .colgauchelarge {
    margin: 0 auto;
    float: none; }
  .page_titre .page_titre2 {
    font-size: 40pt; } }

/*correctifs vieux navigateurs*/
