
/*general*/


body {
	font-family: "omnes-pro",sans-serif;
	color: $typocolor;
}

.centre {
	max-width: 1200px;
	margin: 0 auto;
}

.desk-display-none {
	display: none;
}

strong{
	font-weight: 600;
}

