
/*agendaseul*/

.agendaseul{
	.colgauchelarge{
		margin-top: 55px;
	}
}


/*liste agenda*/

.listeagenda_item{
	margin: 30px 0;
	.date_evenement{
		display: table-cell;
		vertical-align: top;
	}
	.meta_evenement{
		float: none;
	}
	.texte_evenement{
		line-height: 1.2;
		display: table-cell;
		vertical-align: top;
		h3{
			font-size: $f28;
			font-weight: 600;
			margin-bottom: 0.2em;
		}
	}
	.lien_evenement{
		color: $secondcolor;
		font-weight: 600;
		text-decoration: underline;
		margin: 15px 0;
		display: inline-block;
		&:hover, &:active, &:focus{
			color: $thirdcolor;
		}
	}
}


/*liste actualites*/

.listeactu{
	@include clear;
}

.listeactu_actu{
	background: $neutreclair;
	display: inline-block;
	width: 47.368%; /*360/760*/
	margin: 2.632% 0; /*20/760*/
	float: left;
	position: relative;
	&:nth-of-type(odd){
		margin-right: 5.263%;/*40/760*/
	}
	h3, h4{
		line-height: 1.25;
		padding: 4px;
	}
	.meta_publication{
		font-weight: 600;
		font-size: $f15;
		color: $typoop60;
		margin: 0;
		padding: 6px 4px;
	}
	.categorie{
		position: absolute;
		top: -12px;
		right: 10px;
		background: $secondcolor;
		color: $light;
		font-weight: 600;
		text-transform: uppercase;
		font-size: $f14;
		padding: 5px 13px;
	}
	img{
		@include transition;
	}
	&:hover, &:active, &:focus {
		background: $neutreop60;
		img{
			opacity: 0.7;
		}
	}
}
/*articles liés*/

.articles_lies{
	h3{
		@include titre3;
	}
	.listeactu_actu{
		width: 31.579%; /*240/760*/
		margin-right: 2.632%;  /*20/760*/
		&:nth-of-type(odd){
			margin-right: 2.632%;  /*20/760*/
		}
		&:nth-of-type(3n){
			margin-right: 0;
		}
	}
	
}

/*infos pratiques*/


.js-tabcontent[aria-hidden=true] { display: none; }

.infos_pratiques{
	h3{
		@include titre3;
	}
	.tabs__list{
		background: $neutreclair;
	}
	.tabs__item{
		display: table-cell;
		height: 50px;
		line-height: 50px;
	}
	.tabs__link{
		text-transform: uppercase;
		font-weight: 600;
		display: block;
		padding: 0 10px;
		height: 100%;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		&::after{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 2px;
			background: $typocolor;
			content: '';
			@include transition;
			transform: translateY(-6px);
		}
		&[aria-selected=true]{
			background: $light;
			&::after{
				transform: translateY(0);
			}
		}
		&:hover, &:active, &:focus{
			color: $mauve;
			outline: 0;
		}
		
	
	}
	.invisible{
		visibility: hidden;
		font-size: 0;
		line-height: 0;
	}
	
}


/*inscription*/

.inscription{
	@include titre3;
	form{
		margin: 30px 0 0 0;
	}
}


/*mon profil*/

.profil_info{
	margin-top: 57px;
	margin-bottom: 30px;
	.portrait{
		width: 200px;
		float: left;
		margin-right: 30px;
	}
	h3{
		@include titre3;
		margin-top: 0px;
		margin-bottom: 0px;
		.nom{
			text-transform: uppercase;
		}
	}
	.champ_info{
		font-weight: 400;
		font-size: $f18;
		line-height: 1.25;
	}
	.champ_info_societe{
		font-weight: 600;
	}
	.champ_info_fonction{
		font-style: italic;
		margin-bottom: 10px;
	}
	.champ_info_region {
		margin-bottom: 10px;
	}
	.champ_mail{
		display: inline-block;
		font-size: $f18;
		font-weight: 600;
		color: $secondcolor;
		&:hover, &:active, &:focus {
			color: $thirdcolor;
		}
	}
	.champ_rs{
		margin: 5px 0;
		img{
			width: 20px;
		}
		a{
			display: inline-block;
			margin-right: 20px;
			&:hover, &:active, &:focus {
				img{
					transform: scale(1.1);
				}
			}
		}
	}
}

.btn_wrapper_profil{
	text-align: center;
	padding: 30px 0 0 0;
	.bouton{
		@include bouton;
		&:hover, &:active, &:focus {
			background: $typocolor;
			color: $light;
			border-color: $typocolor;
		}
	}
}
.profil{
	.cat_profil {
		color: $light;
		font-size: $f14;
		text-transform: uppercase;
		font-weight: 600;
		background: $secondcolor;
		display: inline-block;
		padding: 5px 15px;
		margin-right: 5px;
		margin-bottom: 5px;
	}
	.titre4{
		font-size: $f20;
		font-weight: 600;
		text-transform: uppercase;
		margin-bottom: 25px;
		margin-top: 50px;
	}
	.list_groupe{
		padding: 0 0 0 14px;
	}
	.elmt_groupe{
		list-style-type: square;
		margin: 5px 0;
	}
}


/*extranet annuaire*/

.glossaire{
	text-transform: uppercase;
	margin-top: -20px;
	.glossaire_entre{
		display: inline-block;
		width: 20px;
		height: 20px;
		border: 1px solid $light;
		border-radius: 10px;
		text-align: center;
		&:hover, &:focus, &:active{
			border-color: $typocolor;
		}
		&.current{
			font-weight: 600;
			&:hover, &:focus, &:active{
				border-color: $light;
			}
		}
	}
}

.wrapper-annuaire{
	overflow: hidden;
	padding-top: 50px;
	font-size: 0;
}
.personne{
	font-size: $f16;
	vertical-align: top;
	background: $neutreclair;
	display: inline-block;
	width: 30.263%; /*230/760*/
	margin-top: 2.632%; /*20/760*/
	margin-bottom: 2.632%; /*20/760*/
	margin-right: 4.605%;/*35/760*/
	position: relative;
	padding-bottom: 10px;
	&:nth-of-type(3n){
		margin-right: 0;
	}
	h3{
		line-height: 1.25;
		padding: 4px 15px 4px 15px;
		font-weight: 600;
	}
	.champ_info{
		padding: 2px 15px 2px 15px;

	}
	.champ_info_fonction{
		font-style: italic;
	}
	.categorie{
		position: absolute;
		top: -12px;
		right: 10px;
		background: $secondcolor;
		color: $light;
		font-weight: 600;
		text-transform: uppercase;
		font-size: $f14;
		padding: 5px 13px;
	}
	img{
		@include transition;
	}
	&:hover, &:active, &:focus {
		background: $neutreop60;
		img{
			opacity: 0.7;
		}
	}
}


/*page extranet groupe*/

.elmt_groupe{
		line-height: 1.3;
		margin: 40px 0;
	.titre3{
		font-weight: 600;
		font-size: $f20;
		margin-bottom: 20px;
		a:hover, a:active, a:focus {
			color: $secondcolor;
		}
		&.prive{
			a{
				padding-left: 24px;
				background: url(../images/picto_cadenas_violet.png) no-repeat left center;
			}
		}
	}
	.separateur{
		font-weight: 400;
	}
	.thematique_lien{
		color: $secondcolor;
		font-weight: 400;
	}
	.compteur_membres{
		img{
			vertical-align: text-bottom;
			display: inline-block;
			margin: 5px 5px 0 5px;
		}
	}
	.points{
		font-size: $f36;
	}
}



/*page extranet home*/

.lien_acces{
	margin-top: 100px;
	.lien_public, .lien_restreint{
		font-weight: 600;
		color: $light;
		text-transform: uppercase;
		background-color: $secondcolor;
		display: inline-block;
		padding: 7px 20px 7px 40px;
		margin-bottom: 10px;
		background-repeat: no-repeat;
		background-position: left 10px center;
		&:active, &:focus, &:hover{
			background-color: $typocolor;
		}
	}
	.lien_public{
		background-image: url(../images/picto_groupe_blanc.png);
	}
	.lien_restreint{
		background-image: url(../images/picto_cadenas_blanc.png);
	}
}
.sondage {
	margin-top: 50px;
	font-weight: 600;
	background: $neutreclair;
	border: solid 3px $typocolor;
	padding: 30px 40px;
	line-height: 1.3;
	.titre {
		font-size: $f26;
		margin-bottom: 17px;
	}
	.description{
	}
}

.thematiques_home{
	font-size: 0;
	margin: 50px -20px 0 -20px;
	.titre3{
		text-transform: uppercase;
		font-weight: 600;
		font-size: $f20;
		margin-left: 20px;
	}
	.thematique_item{
		width: 160px;
		height: 160px;
		display: inline-block;
		margin: 20px;
		font-size: $f18;
		vertical-align: top;
		text-align: center;
		line-height: 160px;
		font-weight: 600;
		text-transform: uppercase;
		padding: 0px;
		h4{
			display: inline-block;
			line-height: normal;
			vertical-align: middle;
			
		}
		&:hover, &:active, &:focus {
			opacity: 0.7;
		}
	}
	.conteneur_bouton_thematique{
		font-size: $f16;
		text-align: center;
		margin-top: 20px;
		.bouton{
			display: inline-block;
			background: $typocolor;
			color: $light;
			font-weight: 600;
			text-transform: uppercase;
			border:  2px solid $typocolor;
			padding: 6px 15px;
			&:hover, &:active, &:focus {
				background: $light;
				color: $typocolor;
			}
		}
	}
}
.liste_groupe_thematique{
	margin-top: 50px;
	h3{
		text-transform: uppercase;
		font-weight: 600;
		font-size: $f20;
	}
	.titre3{
		font-size: $f18;
		text-transform: none;
	}
	.conteneur_bouton{
		font-size: $f16;
		text-align: center;
		margin-top: 20px;
		.bouton{
			display: inline-block;
			background: $typocolor;
			color: $light;
			font-weight: 600;
			text-transform: uppercase;
			border:  2px solid $typocolor;
			padding: 6px 15px;
			&:hover, &:active, &:focus {
				background: $light;
				color: $typocolor;
			}
		}
	}
}

.contenu_thematiques{
	margin-top: 50px;
}

/*page liste_thematique*/

.elmt_thematique{
	line-height: 1.3;
	margin: 32px 0;
	.tn3 a{
		text-transform: uppercase;
		font-weight: 600;
		font-size: $f20;
		margin-bottom: 20px;
		background-image: url(../images/picto_entre_violet.png);
		background-repeat: no-repeat;
		display: inline-block;
		padding-right: 30px;
		background-position: right center;
		&:hover, &:active, &:focus {
			color: $secondcolor;
		}
	}
	.presentation{
		font-size: $f18;
	}
	.last_discussion{
		color: $secondcolor;
		font-weight: 600;
		a:hover, a:focus, a:active{
			text-decoration: underline;
		}
	}
	&.prive{
		.tn3 a{
			background-image: url(../images/picto_entre_violet.png), url(../images/picto_cadenas_violet.png);
			background-repeat: no-repeat;
			padding-right: 65px;
			background-position: right center, right 30px center;
			
		}
	}
}


