

/*formulaires*/

.contenu-editable form ul {
	list-style-type: none;
	padding: 0;
}
	
.gform_body{
	.gfield{
		margin: 10px 0;
		.gfield_label{
			text-transform: uppercase;
		}
		input[type="text"], input[type="email"], input[type="tel"], input[type="file"] {
			display: block;
			border: 2px solid $neutreclair;
			padding: 5px 0px 5px 10px;
			@include omnes;
			color: $typocolor;
			font-size: $f14;
			&::placeholder{
				font-style: italic;
				color: $typocolor;
			}
		}
		input.large{
			width: 100%;
		}
		textarea{
			display: block;
			border: 2px solid $neutreclair;
			@include omnes;
			color: $typocolor;
			font-size: $f14;
			width: 100%;
			padding: 5px 0px 0px 10px;
			min-height: 101px;
			&::placeholder{
				font-style: italic;
				color: $typocolor;
			}
		}
	}
	
	.checkbox-label{
		display: inline-block;
		font-size: $f16;
		font-weight: normal;
		
		a{
			text-decoration: underline;
			
			&:hover,
			&:focus,
			&:active{
				color: $secondcolor;
				text-decoration: none;
			}
		}
	}
			
}
.gform_footer{
	.button {
		cursor: pointer;
		padding: 2px 35px;
		@include omnes;
		font-size: $f16;
		font-weight: 600;
		color: $light;
		background: $typocolor;
		border: solid 2px $typocolor;
		text-transform: uppercase;
		@include transition;
		&:hover, &:active, &:focus {
			background: $light;
			color: $typocolor;
		}
	}
}
.gform_validation_error {
	font-size: 1.2em;
}
.gform_confirmation_message {
	font-size: 1.2em;
}


/*select*/

.ui-selectmenu-button{
	border: solid 2px $neutreclair;	
	padding: 2px;
	font-style: italic;
	color: $mauve;
	margin: 0 10px;
	&:hover, &:active, &:focus {
		border-color: $neutremoyen;
	}
}

.ui-selectmenu-icon{
	background-image: url(../images/select_icon.png);
	background-position: center center;
	width: 28px;
	height: 28px;
}

.ui-widget-content{
	border: solid 2px $neutreclair;
	border-top: none;
	.ui-menu-item{
		padding: 5px 0;
		background: $light;
		&:hover, &:active, &:focus{
			background: $neutreclair;
		}
	}	
	
}


.filtre{
	vertical-align: top;
	.ui-selectmenu-button{
		width: 10em;
	}
	.wrapper_filtre_recherche{
		display: inline-block;
		vertical-align: bottom;
	}
	.filtre_input{
		vertical-align: bottom;
		border: solid 2px $neutreclair;	
		padding: 2px 2px 2px 28px;;
		font-style: italic;
		color: $mauve;
		margin: 0 10px;
		@include omnes;
		font-size: $f16;
		line-height: 24px;
		height: 28px;
		background: url(../images/filtre_loupe@2x.png) no-repeat 2px center;
		background-size: 16px;
		&:hover, &:active, &:focus {
			border-color: $neutremoyen;
		}
		&::placeholder{
			opacity: 1;
			
		}
	}
	.filtre_button{
		border: none;
		vertical-align: bottom;
		padding: 0;
		background: 0;
		cursor: pointer;
		height: 36px;
		img{
			width: 36px;
		}
		&:hover, &:active, &:focus{
			opacity: 0.7;
		}
	}
}



/*formulaire de connexion*/

#loginform{
	p{
		margin: 10px 0;
		label{
			text-transform: uppercase;
			display: inline-block;
			margin: 20px 0 10px;
		}
		.input {
			display: block;
			border: 2px solid $neutreclair;
			padding: 5px 0px 5px 10px;
			@include omnes;
			color: $typocolor;
			font-size: $f14;
			width: 100%;
			max-width: 300px;
		}
	}
			
}
.login-submit{
	#wp-submit {
		cursor: pointer;
		padding: 2px 35px;
		@include omnes;
		font-size: $f16;
		font-weight: 600;
		color: $light;
		background: $typocolor;
		border: solid 2px $typocolor;
		text-transform: uppercase;
		@include transition;
		&:hover, &:active, &:focus {
			background: $light;
			color: $typocolor;
		}
	}
}





/*Commentaires*/


.comments-area{
	margin-top: 40px;
	a:hover, a:active, a:focus {
		text-decoration: underline;
	}
	.comment{
		padding-left: 10px;
		border-left: 1px solid $neutreclair;
	}
	.children{
		padding-left: 10px;
	}
	.comment-author{
		img{
			display: inline-block;
			vertical-align: middle;
		}
		.fn{
			display: inline-block;
			font-weight: 600;
			margin: 0 5px;
		}
		.says{
			display: inline-block;
		}
	}
	.comment-meta{
		color: $mauve;
		margin: 4px 0 15px 0;
		font-size: $f14;
	}
	.reply{
		margin: 15px 0 40px 0;
	}
	.comment-reply-link {
		color: $secondcolor;
		font-size: $f14;
	}
	.comment-reply-title, .comments-title{
		text-transform: uppercase;
		font-weight: 600;
		font-size: $f20;
		margin: 1.3em 0;
	}
	.logged-in-as{
		margin: 1.3em 0;
		a:last-of-type{
			color: $secondcolor;
		}
	}
	.comment-respond{
		margin-top: 50px;
	}
}

.comment-form{
	label{
		color: $mauve;
		margin-bottom: 10px;
		display: inline-block;
	}
	textarea{
		display: block;
		border: 2px solid $neutreclair;
		@include omnes;
		color: $typocolor;
		font-size: $f14;
		width: 100%;
		padding: 5px 0px 0px 10px;
		min-height: 101px;
		&::placeholder{
			font-style: italic;
			color: $typocolor;
		}
	}
	input.submit{
		margin-top: 30px;
		cursor: pointer;
		padding: 2px 35px;
		@include omnes;
		font-size: $f16;
		font-weight: 600;
		color: $light;
		background: $typocolor;
		border: solid 2px $typocolor;
		text-transform: uppercase;
		@include transition;
		&:hover, &:active, &:focus {
			background: $light;
			color: $typocolor;
		}
		
	}
}







/*fenêtre de partage*/

#modalOverlay {
	width:100%;
	height:100%;
	z-index:2; /* on place le calque au dessus du contenu et en dessous de la fenÃªtre modale */
	background-color:#000;
	opacity:0.5;
	position:fixed;
	top:0;
	left:0;
	display:none;
	margin:0;
	padding:0;
}
#modal {
	text-align: center;
	width:400px;
	margin-left: -200px;
	padding: 5px;
	border: 3px #fff solid;
	background-color:#fff;
	z-index:3; /* On place la fenÃªtre modale au dessus de tout le reste */
	position:fixed;
	top:25%;
	left:50%;
	display:none;
}
#modalTitle {
	margin: 1.3em 0;
	font-weight: 600;
	font-size: $f28;
	text-align: center;
	line-height: 1.3;
}
.modalCloseButton {
	float:right;
	position:absolute;
	top:0px;
	right: 0px;
	font-size: $f28;
	color: $typocolor;
	font-weight: 600;
}
.screen-reader-offscreen {
   position:absolute;
   left:-999px;
   width:1px;
   height:1px;
   top:auto;
}
#modal input[type=text],#modal input[type=email] {
	margin: 0 auto;
	display: block;
	border: 2px solid $neutreclair;
	padding: 5px 0px 5px 10px;
	@include omnes;
	color: $typocolor;
	font-size: $f14;
	width: 70%;
}
#modal label {
 	text-transform: uppercase;
}
input[type=submit] {
	margin-top: 30px;
	cursor: pointer;
	padding: 2px 35px;
	@include omnes;
	font-size: $f16;
	font-weight: 600;
	color: $light;
	background: $typocolor;
	border: solid 2px $typocolor;
	text-transform: uppercase;
	@include transition;
	&:hover, &:active, &:focus {
		background: $light;
		color: $typocolor;
	}
}