
/*structure*/

.colgauche{
	width: 33.333%; /*400/1200*/
	float: left;
}
.coldroite{
	width: 66.667%; /*800/1200*/
	float: right;
}



.colgauchelarge{
	width: 66.667%; /*800/1200*/
	float: left;
}

.coldroiteetroite{
	width: 33.333%; /*400/1200*/
	float: right;
	
}

.menufixe2{
	margin-top: 100px;
}