
/*------------------
	---------SLIDER------------
	----------------------------*/
	

.slider{
	.slide{
		position: relative;
		width: 100%;
		max-height: 500px;
		max-width: 1800px;
		margin: 0 auto;
		img{
			display: block;
		}
		.blockquote_wrapper{
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: -moz-linear-gradient(left,  rgba(238,233,239,0) 0%, rgba(238,233,239,0.8) 8%, rgba(238,233,239,0.8) 67%, rgba(238,233,239,0) 77%, rgba(238,233,239,0) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(left,  rgba(238,233,239,0) 0%,rgba(238,233,239,0.8) 8%,rgba(238,233,239,0.8) 67%,rgba(238,233,239,0) 77%,rgba(238,233,239,0) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to right,  rgba(238,233,239,0) 0%,rgba(238,233,239,0.8) 8%,rgba(238,233,239,0.8) 67%,rgba(238,233,239,0) 77%,rgba(238,233,239,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00eee9ef', endColorstr='#00eee9ef',GradientType=1 ); /* IE6-9 */
			display: flex;
		}
		.centre{
			margin: 20% auto;
			margin: auto;
		}
		blockquote{
			font-size: 2.25em;
			margin: auto 0;
			width: 66.667%;/*800/1200*/
			position: relative;
			padding-left: 4.5%;/*54/1200*/
			&::before{
				content: '';
				position: absolute;
				background-image: url(../images/blockquote-before.png);
				background-repeat: no-repeat;
				top: -1.3em;
				left: 0.2em;
				width: 2.41em;
				height: 2.05em;
				background-size: contain;
			}
			&::after{
				content: '';
				position: absolute;
				background-image: url(../images/blockquote-after.png);
				background-repeat: no-repeat;
				position: absolute;
				bottom: -1.9em;
				right: -1.4em;
				width: 2.41em;
				height: 2.05em;
				background-size: contain;
			}
		}
		.citation{
			font-style: italic;
			line-height: 1.2;
			padding-bottom: 1.14em;
			strong{
				font-weight: 600;
			}
		}
		.bouton{
			@include bouton;
			border-color: $typocolor;
			&:focus, &:active, &:hover{
				background: $typocolor;
				color: $light;
			}
		}
		.legend{
			float: right;
			text-align: right;
			line-height: 1.2;
			font-size: $f18;
			span{
				display: block;
				font-size: $f16;
			}
		}		
	}
	.owl-nav{
		
	}
	.owl-prev{
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		background: url(../images/slider-prev.png) no-repeat;
		font-size: 0;
		opacity: 0.5;
		width: 04.888%; /*88/1800*/
		height: 32%; /*160/500*/
		background-size: contain;
		@include transition;
		&:focus, &:active, &:hover{
			opacity: 1;
		}
	}
	.owl-next{
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		background: url(../images/slider-next.png) no-repeat;
		font-size: 0;
		width: 04.888%; /*88/1800*/
		height: 32%; /*160/500*/
		background-size: contain;
		opacity: 0.5;
		@include transition;
		&:focus, &:active, &:hover{
			opacity: 1;
		}
	}
}	





@media screen and (max-width: 1400px){
	
.slider{
	.slide{
		blockquote{
			font-size: 2em;
		}
	}
}

}
@media screen and (max-width: 1200px){
	
.slider{
	.slide{
		blockquote{
			margin: 0 8%;
			font-size: 1.75em;
		}
	}
}

}
@media screen and (max-width: 1000px){
	
.slider{
	.slide{
		blockquote{
			font-size: 1.25em;
		}
	}
}

}
@media screen and (max-width: 800px){
	
.slider{
	.slide{
		.blockquote_wrapper{
			background: rgba(238,233,239,0.8)
		}
		blockquote{
			font-size: 1.10em;
		}
	}
}

}

@media screen and (max-width: 700px){
	
.slider{
	.slide{
		blockquote{
			width: auto;
			padding: 0;
			&::before{
				left: -1.5em;
			}
		}
	}
}

}

@media screen and (max-width: 640px){
	
.slider{
	.slide{
		.blockquote_wrapper{
			position: static;
			height: 300px;
		}
		blockquote{
			font-size: 1em;
		}
	}
	.owl-prev, .owl-next{
		width: 22px;
		height: 40px;
	}
}

}



/*gestion du z-index du carousel*/
.owl-carousel{
	z-index: 0 !important;
}

/*commit de test*/