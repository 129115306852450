
/*blocs*/


.contenu-editable {
	line-height: 1.37em;
	font-size: $f16;
	a{
		color: $secondcolor;
	}
	h3{
		margin: 2.3em 0 1em 0;
		font-weight: 600;
		font-size: $f28;
	}
	h4{
		margin: 2.3em 0 1em 0;
		font-weight: 600;
		font-size: $f20;
		text-transform: uppercase;
				
	}
	p{
		margin: 1.2em 0 1.2em 0;
	}
	ul{
		list-style-type: disc;
		margin: 1.2em 0 1.2em 0;
		padding: 0 0 0 33px;
	}
	ol{
		list-style-type: decimal;
		margin: 1.2em 0 1.2em 0;
		padding: 0 0 0 33px;
	}
	a{
		&:hover, &:active, &:focus{
			text-decoration: underline;
		}
	}
	sup{
		vertical-align: super;
	}
	.wp-caption-text{
		font-style: italic;
	}
	embed, iframe, object
	{
	max-width: 100%;
	}
}


.page_titre{
	overflow: hidden;
	background: $neutreclair;
	padding: 69px 0 78px 0;
	.page_titre2{
		@include pagetitre2;
	}
	.ariane{
		font-size: $f14;
		color: $violet2;
		margin: 7px 0 0 0;
		line-height: 1.2;
		a{
			text-decoration: underline;
			&:hover, &:active, &:focus{
				color: $secondcolor;
			}
		}
		.current{
			color: $mauve;
		}
	}
	.ariane_chevron{
		display: inline-block;
		margin: 0 5px;
	}
}


/*partage*/

.partage{
	background: $typocolor;
	color: $light;
	font-weight: 600;
	text-transform: uppercase;
	font-size: $f14;
	max-width: 398px;
	text-align: center;
	height: 44px;
	width: 100%;
	display: table;
	position: absolute;
	top: -21px;
	img{
		max-height: 18px;
	}
	.partage_texte{
		display: table-cell;
		vertical-align: middle;
		width: 35%;
	}
	.partage_liens{
		display: table-cell;
		vertical-align: middle;
		width: 65%;
	}
	a{
		display: inline-block;
		margin: 0 9px;
		&:hover, &:focus, &:active{
			img{
				transform: scale(1.1);
			}
		}
	}
}


/*Nouvelle discussion*/

.new_discussion{
	background: $typocolor;
	color: $light;
	font-weight: 600;
	text-transform: uppercase;
	font-size: $f14;
	max-width: 398px;
	text-align: center;
	height: 44px;
	line-height: 44px;
	width: 100%;
	display: table;
	position: absolute;
	top: -21px;
	img{
		margin-right: 10px;
		vertical-align: text-bottom;
	}
	&:hover, &:active, &:focus {
		background: $secondcolor;
	}
}




/*documents liés*/

.documentslies{
	h3{
		@include titre3;
	}
	a{
		display: block;
		padding: 20px 20px 20px 80px;
		margin-bottom: 2px;
		font-size: $f16;
		background: $neutreclair url(../images/ico-telecharger@2x.png) no-repeat left 40px center;
		background-size: 20px;
		&:hover, &:active, &:focus {
			background-color: $neutremoyen;
		}
	}
	.upper{
		text-transform: uppercase;
	}
}


/*meta date de publication*/

.meta_publication{
	font-size: $f16;
	color: $mauve;
	margin-top: 55px;
}

/*meta date de evenement*/

.meta_evenement{
	float: left;
	color: $light-op5;
	background: $typocolor;
	padding: 30px 0px 35px 0px;
	font-size: $f36;
	font-weight: 600;
	margin-right: 40px;
	line-height: 1.2;
	width: 170px;
	text-align: center;
	.article{
		font-size: $f24;
	}
}


/*localisation*/

.localisation{
	h3{
		@include titre3;
	}
	#canvas-carto{
		width: 100%;
		height: 527px;
	}
	#direction{
		label{
			font-size: $f20;
		}
	} 
	
	.localisation_wrapper{
		display: table;
		width: 100%;
		border: 2px $neutreclair solid;
		max-width: 400px;
		margin: 30px 0;
		.wrapper_input{
			width: 67%;
			float: left;
			input{
				border: none;
				@include omnes;
				font-size: $f14;
				color: $typocolor;
				padding: 0 2px 0px 5px;
				min-height: 28px;
				line-height: 28px;
				width: 100%;
			}
		}
		button{
			border-right: 0 none;
			border-top: 0 none;
			border-bottom: 0 none;
			@include omnes;
			border-left: 2px $neutreclair solid;
			font-size: $f14;
			text-transform: uppercase;
			font-weight: 600;
			width: 32%;
			float: right;
			color: $typocolor;
			min-height: 26px;
			line-height: 26px;
			cursor: pointer;
			background: none;
			@include transition;
			&:focus, &:hover, &:active{
				background: $typocolor;
				color: $light;
			}
		}
		::-webkit-input-placeholder { color:$typocolor; font-style: italic;}
		::-moz-placeholder { color:$typocolor; font-style: italic;} /* firefox 19+ */
		:-ms-input-placeholder { color:$typocolor; font-style: italic;} /* ie */
		input:-moz-placeholder { color:$typocolor; font-style: italic;}	
	}
}


/*filtre*/

.filtre{
	margin: 20px 0 50px 0;
	label, .label{
		font-size: $f18;
		font-weight: 600;
	}
}

/*pagination*/

.pagination{
	text-align: center;
	padding-top: 40px;
	.screen-reader-text{
		font-size: 0;
	}
	.page-numbers{
		padding: 0 0 0 5px;
		&::after{
			content: '|';
			display: inline-block;
			margin: 0 0 0 5px;
			font-size: $f20;
			font-weight: 400;
			color: $typocolor;
		}
		&:last-child{
			&::after{
				content: '';	
			}
		}
		&.current{
			font-weight: 600;
			color: $mauve;
			&:hover, &:active, &:focus{
				color: $mauve;
			}
		}
		&:hover, &:active, &:focus{
			color: $secondcolor;
			&:after{
				color: $typocolor;
			}
		}
	}
}

