
/* Sidebar*/


.sb_actualite{
	background: $light;
	color: $typocolor;
	border: solid 2px $neutreclair;
	text-align: center;
	padding: 60px 8.75%; /*35/400*/
	.titre2{
		@include titre2;
		&::after{
			background: $typocolor;
		}
	}
	.sbactu_article{
		display: table;
		width: 100%;
		text-align: left;
		padding: 10px;
		margin: 10px 0;
		&:focus, &:active, &:hover{
			background: $neutreclair;
		}
	}
	.sbactu_img{
		display: table-cell;
		width: 37%;
		padding-right: 10px;
		font-weight: 600;
		color: $light-op28;
		font-size: $f32;
		
	}
	.sbactu_titre{
		display: table-cell;
		font-weight: 600;
		vertical-align: top;
		line-height: 1.2;
		
	}
	.meta_publication{
		margin: 5px 0 0 0;
		font-size: $f12;
		color: $mauve;
		font-weight: 400;
	}
	.bouton{
		margin-top: 20px;
		@include bouton;
		color: $typocolor;
		border-color: $typocolor;
		&:focus, &:active, &:hover{
			background: $typocolor;
			color: $light;
		}
	}
}

.sb_agenda{
	background: $typocolor;
	color: $light;
	text-align: center;
	padding: 60px 8.75%; /*35/400*/
	.titre2{
		@include titre2;
		&::after{
			background: $light;
		}
	}
	.agenda_event{
		display: table;
		width: 100%;
		text-align: left;
		padding: 10px;
		margin: 10px 0;
		&:focus, &:active, &:hover{
			background: $light-op28;
		}
	}
	.event_date{
		display: table-cell;
		width: 37%;/*102/330*/
		font-weight: 600;
		color: $light-op5;
		font-size: $f26;
		.article{
			font-size: $f16;
		}
		
	}
	.event_title{
		display: table-cell;
		vertical-align: top;
		line-height: 1.2;
		
	}
	.bouton{
		margin-top: 20px;
		@include bouton;
		color: $light;
		border-color: $light;
		&:focus, &:active, &:hover{
			background: $light;
			color: $typocolor;
		}
	}
}
.sb_tweet{
	background: $secondcolor;
	padding: 55px 8.75%; /*35/400*/
	color: $light;
	.titre2{
		@include titre2;
		&:after{
			background-color: $light;
		}
	}
	.tweet_wrapper{
		margin-top: 25px;
		
	}
	.tweet_logo{
		display: table-cell;
		width: 20.303%;/*67/330*/
		padding-right: 10px;
	}
	.tweet_content{
		display: table-cell;
		vertical-align: top;
		font-size: $f18;
		line-height: 1.2;
		a{
			font-weight: 600;
			&:hover, &:active, &:focus {
				color: $typocolor;
			}
		}
		.tweet_metadonnees{
			font-size: $f12;
			display: block;
			margin-bottom: 10px;
			a{
				font-weight: 400;
			}
		}
	}
}

.sb_rs{
	background: $secondcoloralt;
	padding: 55px 8.75%; /*35/400*/
	color: $light;
	.titre2{
		@include titre2;
		&::after{
			background: $light;	
		}
	}
	.rs-wrapper{
		width: 100%;
		display: table;
	}
	.rs-lien{
		display: table-cell;
		text-align: center;
		img{
			width: 30px;
		}
		&:hover, &:focus, &:active {
			img{
				transform: scale(1.1);
			}
		}
	}
}


.sb_trophee{
	background: $neutremoyen;
	text-align: center;
	padding: 55px 14px 135px;
	position: relative;
	.titre2{
		@include titre2;
		&::after{
			background: $typocolor;
		}
	}
	.trophee_presentation{
		font-size: $f20;
		font-style: italic;
		margin: 20px 0;
		padding: 0 14px;
	}
	.bouton{
		@include bouton;
		margin: 15px 0 30px 0;
		border-color: $typocolor;
		&:focus, &:active, &:hover{
			background: $typocolor;
			color: $neutremoyen;
		}
	}
	.trophee_img{
		position: absolute;
		bottom: 0;
		display: block;
		max-width: 106.25%;
		left: 50%;
		transform: translateX(-50%);
	}
}

.sb_profil{
	background: $light;
	color: $typocolor;
	border: solid 2px $neutreclair;
	padding: 60px 8.75%; /*35/400*/
	font-weight: 600;
	.header_sbprofil{
		overflow: hidden;
	}
	.portrait{
		display: block;
		float: left;
		img{
			width: 50px;
			margin-right: 13px;
		}
	}
	.titre{
		font-size: $f18;
		margin-bottom: 4px;
	}
	.deconnecter{
		color: $secondcolor;
		&:hover, &:active, &:focus {
			color: $thirdcolor;
		}
	}
	.croix{
		display: inline-block;
		margin-right: 10px;
		text-transform: capitalize;
	}
	.navigation{
		margin-top: 10px;
	}
	.nav_elemt{
		background-image: url(../images/profil_liste_barre@2x.png);
		background-repeat: no-repeat;
		background-position: left center;
		display: inline-block;
		padding: 5px 0 5px 35px;
		background-size: 20px;
		margin: 5px 0 0 0;
		&:active, &:focus, &:hover{
			text-decoration: underline;
		}
	}
	.current{
		background-image: url(../images/profil_liste_point@2x.png);
	}
	
}





.extranet .sb_actualite{
	color: $light;
	background: $typocolor;
	border: none;
	.titre2::after{
		background: $light;
	}
	.sbactu_article{
		&:focus, &:active, &:hover{
			background: $light-op28;
		}
	}
	.bouton{
		color: $light;
		border-color: $light;
		&:focus, &:active, &:hover{
			background: $light;
			color: $typocolor;
		}
	}
}



.sb_activitesrecentes{
	background: $secondcolor;
	padding: 55px 8.75%; /*35/400*/
	color: $light;
	.titre2{
		@include titre2;
		&:after{
			background-color: $light;
		}
	}
	.activite{
		display: block;
		text-align: left;
		padding: 10px;
		margin: 10px 0;
		font-weight: 400;
		&:focus, &:active, &:hover{
			background: $light-op28;
		}
	}
	.fil{
		font-style: italic;
	}
}




@media screen and (max-width: 1140px){
	.sb_actualite{
		.sbactu_titre{
			font-size: $f14;
		}
	}
	.sb_agenda{
		.event_date{
			font-size: $f20;
		}
		.event_title{
			font-size: $f14;
		}
	}
	.sb_tweet{
		.tweet_content{
			font-size: $f14;
		}
		
	}
	.sb_trophee{
		padding-bottom: 114px;
	}
	.sb_activitesrecentes{
		.activite{
			font-size: $f14;
		}
	}

}

@media screen and (max-width: 920px){
	.sb_agenda{
		display: table-cell;
		width: 50%;
		.event_date{
			font-size: $f17;
		}
	}
	.sb_wrapper--tweetrs{
		display: table-cell;
		width: 50%;
		background: $secondcoloralt;
	}
	.sb_trophee{
		padding-bottom: 100px;
	}
	.sb_trophee{
		padding-bottom: 50px;
		margin-bottom: 50px;
		.trophee_img{
			position: static;
			transform: none;
			max-width: 100%;
			margin: 0 auto;
		}
	}
	
}

@media screen and (max-width: 650px){
	.sb_agenda{
		display: block;
		width: 	auto;
		.event_date{
			width: 20%;
		}
	}
	.sb_actualite{
		display: block;
		width: 	auto;
		.sbactu_img{
			width: 20%;
			padding-right: 20px;
		}
	}
	.sb_wrapper--tweetrs{
		display: block;
		background: none;
		width: auto;
	}
}


@media screen and (max-width: 400px){
	.sb_actualite{
		.sbactu_img{
			width: 40%;
		}
	}
	.sb_agenda{
		.event_date{
			width: 40%;
		}
	}
	.sb_rs{
		.rs-lien{
			img{
				width: 20px;
			}
		}
	}
}