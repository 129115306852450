
/*-------accueil--------*/

.actuagenda{
	padding-bottom: 55px;
	position: relative;
	@include clear;
}
.sidebar{
	margin-top: -21px;
}
.sidebar--mob{
	display: none;
}	



.actualites{
	margin: 54px 1.25% 0/*10/800*/;
	text-align: center;
	.titre2{
		@include titre2;
		margin-bottom: 0;
		&::after{
			background: $typocolor;
		}
	}
	.actualites_wrapper{
		display: table;
		border-spacing: 20px;
		table-layout: fixed;
	}
	.actualites_article{
		width: 43.589%;/*340/780*/
		padding: 10px;
		display: table-cell;
		img{
			width: 100%;
		}
		&:focus, &:hover, &:active{
			background: $neutreclair;
		}
	}
	.article_date{
		font-size: $f16;
		margin: 13px 0;
		
	}
	.article_titre{
		font-weight: 600;
		font-size: $f20;
		margin: 10px 0;
		line-height: 1.15;
	}
	.article_extrait{
		font-size: $f16;
		text-align: left;
		margin: 10px 0;
		line-height: 1.2;
	}
	.bouton{
		@include bouton;
		border-color: $typocolor;
		margin-top: -5px;
		&:hover, &:focus, &:active{
			background: $typocolor;
			color: $light;
		}
	}
}

.veille{
	text-align: center;
	margin:  54px 2.875% 0; /*23/800*/
	.titre2{
		margin-bottom: 0;
		@include titre2;
		span{
			text-transform: none;
		}
		&::after{
			background: $typocolor;
		}
	}
	.veille_wrapper{
		display: table;
		border-spacing: 7px;
	}
	.veille_article{
		width: 32.095%; /*242/754*/
		display: table-cell;
		padding: 10px;
		&:focus, &:hover, &:active{
			background: $neutreclair;
		}
		img{
			width: 100%;
		}
	}
	.article_date{
		font-size: $f16;
		margin: 13px 0;
		
	}
	.article_titre{
		font-weight: 600;
		font-size: $f18;
		margin: 10px 0;
		line-height: 1.15;
	}
	.bouton{
		@include bouton;
		border-color: $typocolor;
		margin-top: 8px;
		&:hover, &:focus, &:active{
			background: $typocolor;
			color: $light;
		}
	}
}


.reseau{
	background: $thirdcolor;
	text-align: center;
	padding: 55px 0;
	color: $light;
	.titre2{
		@include titre2;
		&::after{
			background: $light;
		}
	}
	.reseau-presentation{
		font-style: italic;
		font-size: $f20;
		line-height: 1.2;
		padding: 20px 60px;
	}
	h3{
		font-size: $f24;
		font-style: italic;
		font-weight: 600;
		margin-top: 15px;
	}
	.bouton{
		@include bouton;
		border-color: $light;
		margin: 9px;
		&:hover, &:active, &:focus{
			color: $thirdcolor;
			background: $light;
		}
	}
}

/*carousel*/

.reseau_carousel{
	width: 88.333% !important; /*1060/1200*/
	margin: 20px 5.83333% 40px 5.83333%; /*70*/
	height: 100px;
	background: $light;
	.membres{
		height: 100px;
		background: $light;
		line-height: 100px;
		img{
			vertical-align: middle;
			display: inline-block !important;
			width: auto !important;
			max-width: 85%;
			max-height: 80px;
		//	margin: 0 30px;
		}
	}
	.owl-prev{
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		background-image: url(../images/reseau-carousel-precedent@2x.png);
		background-repeat: no-repeat;
		background-size: contain;
		width: 12px;
		height: 24px;
		font-size: 0;
		left: -40px;
		&:focus, &:active, &:hover{
		}
	}
	.owl-next{
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		background-image: url(../images/reseau-carousel-suivant@2x.png);
		background-repeat: no-repeat;
		background-size: contain;
		font-size: 0;
		width: 12px;
		height: 24px;
		right: -40px;
		&:focus, &:active, &:hover{
		}
	}
}



.tropheeeco{
	background: $neutreclair;
	@include clear;
	padding: 50px 0 160px 0; //gros padding pour le chevauchement footer
}
.ecosysteme{
	padding: 5px 0 0 0;
	text-align: center;
	.titre2{
		@include titre2;
		margin-bottom: 40px;
		&::after{
			background-color: $typocolor;
		}
	}
}








@media screen and (max-width: 1240px){
	.actuagenda.centre, .tropheeeco .centre{
		margin: 0 14px;
	}
}

@media screen and (max-width: 1140px){
}
@media screen and (max-width: 920px){
	.actuagenda.centre{
		margin: 0 0px;
		padding-bottom: 0;
	}
	.actuagenda .coldroite{
		float: none;
		width: auto;
		margin: 0 auto;
	}
	.sidebar{
		display: none;
	}
	.sidebar--mob{
		display: block;
		margin: 54px auto 0 auto;
		
	}
	.reseau {
		.reseau_carousel{
			.membres{
				img{
				}
			}
			.owl-prev{
				left: -20px;
			}
			.owl-next{
				right: -20px;
			}
		}
	}
	.trophee{
		padding-bottom: 100px;
	}
	.tropheeeco{
		.colgauche {
			float: none;
			width: auto;
		}
		.coldroite{
			float: none;
			width: auto;
		}
	}
	.trophee{
		padding-bottom: 50px;
		margin-bottom: 50px;
		.trophee_img{
			position: static;
			transform: none;
			max-width: 100%;
			margin: 0 auto;
		}
	}
	
}
@media screen and (max-width: 650px){
	.actuagenda.centre{
		margin: 0 14px;
	}
	.actualites{
		margin: 54px 0 0 0;
	}
	.reseau {
		margin: 0 14px;
		.reseau_carousel{
			.membres{
				img{
				}
			}
			.owl-prev{
				left: -20px;
			}
			.owl-next{
				right: -20px;
			}
		}
	}
}

@media screen and (max-width: 550px){
	.actualites{
		margin: 54px 0 0 0;
		.actualites_wrapper{
			display: block;
		}
		.actualites_article{
			display: block;
			width: auto;
			margin: 10px 0;
		}
	}
	.veille{
		margin: 54px 0 0 0;
		.veille_wrapper{
			display: block;
		}
		.veille_article{
			display: block;
			width: auto;
			margin: 10px 0;
		}
	}
}

@media screen and (max-width: 400px){
	.reseau {
		margin: 0 14px;
		.reseau_carousel{
			width: 80% !important;
			margin-right: auto;
			margin-left: auto;
		}
	}		
}