
/*footer*/

footer{
	background: $secondcolor;
	@include clear;
	min-height: 162px;
	position: relative;
	.centre{
		position: relative;
	}
}

.footer_haut{
	@include clear;
	position: absolute;
	width: 	100%;
	background: $gristresclair;
	top: -110px;
	.titre2{
		@include titre2;
		color: $secondcolor;
		text-align: left;
		margin-bottom: 16px;
		&::after{
			background: $secondcolor;
			margin: 9px 0;
		}
	}
	.presentation{
		font-style: italic;
		color: $secondcolor;
		font-size: $f17;
		margin-bottom: 13px;
	}
	.footer_newsletter, .footer_rs, .footer_presse{
		min-height: 220px;
		width: 33.3333%;
		float: left;
		padding: 53px 4% 0px 5%; /*60/1200*/
	}
	
	.newsletter_form_box{
		
		width: 100%;
		@include clear;
		
		.newsletter_form{
			width: 100%;
			@include clear;			
		}
		
		.newsletter_form_input_wrapper{
			border: 2px $secondcolor solid;
			@include clear;			
		}
		
		input[type=email]{
			border: none;
			@include omnes;
			font-size: $f14;
			color: $secondcolor;
			padding: 0 2px;
			width: 67%;
			float: left;
			min-height: 28px;
			line-height: 28px;
			background: $gristresclair;
		}
		button{
			border: none;
			@include omnes;
			border-left: 2px $secondcolor solid;
			font-size: $f14;
			text-transform: uppercase;
			font-weight: 600;
			width: 33%;
			color: $secondcolor;
			float: right;
			min-height: 26px;
			line-height: 26px;
			cursor: pointer;
			background: none;
			@include transition;
			&:focus, &:hover, &:active{
				background: $secondcolor;
				color: $light;
			}
		}
		::-webkit-input-placeholder { color:$secondcolor; font-style: italic;}
		::-moz-placeholder { color:$secondcolor; font-style: italic;} /* firefox 19+ */
		:-ms-input-placeholder { color:$secondcolor; font-style: italic;} /* ie */
		input:-moz-placeholder { color:$secondcolor; font-style: italic;}
	}
	
	.rs-wrapper{
		width: 100%;
		display: table;
	}
	.rs-lien{
		display: table-cell;
		text-align: center;
		img{
			width: 28px;
		}
		&:hover, &:focus, &:active {
			img{
				transform: scale(1.1);
			}
		}
	}
	.bouton{
		@include bouton;
		border-color: $secondcolor;
		color: $secondcolor;
		padding: 6px 20px 8px;
		&:hover, &:focus, &:active {
			background: $secondcolor;
			color: $light;
		}
	}
}
	.footer_bas{
		position: absolute;
		bottom: 20px;
		width: 100%;
		text-align: center;
		color: $light;
		font-size: $f14;
		text-transform: uppercase;
		a{
			display: inline-block;
			margin: 0 10px;
			&:hover, &:focus, &:active{
				text-decoration: underline;
			}
		}
		span{
			display: inline-block;
			margin: 0 10px;
		}
	}




/*---------------- MEDIA QUERIES ---------------------*/



@media screen and (max-width: 1140px){
	.footer_haut{
		.footer_newsletter, .footer_rs, .footer_presse{
			padding: 53px 3% 0px 3%; /*60/1200*/
		}
	}
}

@media screen and (max-width: 920px){ 
	.tropheeeco{
		padding-bottom: 50px !important;
	}
	.footer_haut{
		text-align: center;
		display: block;
		position: static;
		padding: 14px 0;
		.footer_newsletter, .footer_rs, .footer_presse{
			padding: 14px 14px 14px 14px; /*60/1200*/
			width: 300px;
			display: inline-block;
			text-align: left;
			float: none;
			min-height: auto;
			vertical-align: top;
		}
	}
	.footer_bas{
		position: static;
		padding: 15px 0;
		line-height: 1.5em;
	}
	
}



/*Formulaire newsletter gravity temporaire*/

#gform_6{
	font-size: 0;
	input[type=email], input[type=text]{
		border: none;
		@include omnes;
		font-size: $f14;
		color: $secondcolor;
		padding: 0 2px;
		min-height: 28px;
		line-height: 28px;
		background: $gristresclair;
		background: none;
		width: 100%;
		border: 2px $secondcolor solid;
		border-right: 0;
	}
	input[type=submit]{
		padding: 0;
		border: none;
		@include omnes;
		font-size: $f14;
		text-transform: uppercase;
		font-weight: 600;
		color: $secondcolor;
		min-height: 26px;
		line-height: 26px;
		cursor: pointer;
		background: none;
		width: 100%;
		border: 2px $secondcolor solid;
		@include transition;
		&:focus, &:hover, &:active{
			background: $secondcolor;
			color: $light;
		}
	}
	::-webkit-input-placeholder { color:$secondcolor; font-style: italic;}
	::-moz-placeholder { color:$secondcolor; font-style: italic;} /* firefox 19+ */
	:-ms-input-placeholder { color:$secondcolor; font-style: italic;} /* ie */
	input:-moz-placeholder { color:$secondcolor; font-style: italic;}
	.gfield_label{
		display: none;
	}
	li, .ginput_container, .gform_footer, ul{
		margin: 0 !important;
		padding: 0 !important;
		border: 0 !important;
		display: block;
	}
	.gform_body{
		display: inline-block;
		width: 67%;
		vertical-align: top;
		font-size: $f16;
	}
	.gform_footer{
		display: inline-block;
		width: 33%;
		font-size: $f16;
	}
	.validation_error {
		font-size: $f16;
	}
	.gform_wrapper li.gfield.gfield_error, .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
		margin: 0 !important;
	}
}