
/*Css pour impression*/

@media print {
	
.noprint {
	display: none;
}	
	
body { /* Modifications : la couleur de fond de page - la police - l'unité utilisée pour la taille de la police  */
  background-color :#fff;
  font-size :12pt;
}

.topheader_nav, .menu, .partage, .sb_actualite, .sb_agenda, .sb_tweet, .sb_rs, .sb_trophee, footer, #direction {
	display: none;
}
	
.contenu-editable a[href]:after {
  content: " (" attr(href) ")"; /* affichage des URL des liens */
  word-wrap: break-word;
 }
 
 .topheader_logo{
	 position: static;
 	 text-align: center;
}
 
 .page_titre{
  padding: 30px 0;
}

.meta_evenement{
	-webkit-print-color-adjust: exact;
}	

.conteneur_principal .colgauchelarge{
	margin: 0 auto;
	float: none;
}	

.page_titre .page_titre2 {
	font-size: 40pt;
}

}