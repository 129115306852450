
/*mobiles*/




@media screen and (max-width: 1240px){
	.conteneur_principal  {
		margin: 0 auto;
		max-width: 1200px;
		.centre{
			margin: 0 14px;
		}
		.partage, .new_discussion{
			left: 0;
		}
	}
	.page_titre{
		.centre{
			max-width: auto;
			margin: 0 14px;
		}
	}
	.partage, .new_discussion{
		 width: 34.821%;
		 a{
			 margin: 0 8px;
		 }
	}
}


@media screen and (max-width: 1015px){
	.page_titre{
		padding: 30px 0;
		.page_titre2{
			font-size: $f36;
		}
	}
	.partage{
		display: none;
	}
	.new_discussion{
		position: absolute;
		top: -90px;
		min-width: 300px;
	}
	.conteneur_principal{
		.colgauchelarge{
			width: auto;
			float: none;
			padding-right: 0;
			max-width: 760px;
			margin-right: auto;
			margin-left: auto;
		}
		.coldroiteetroite{
			width: auto;
			float: none;
			margin-top: 40px;
			max-width: 440px;
			margin-left: auto;
			margin-right: auto;
			.sb_agenda{
				display: block;
				width: auto;
			}
		}
		.sb_actualite, .sb_agenda, .sb_tweet, .sb_rs, .trophee{
			margin-bottom: 0px;
		}
	}
	.contenu-editable{
		h3{
			font-size: $f24;
		}
		h4{
			font-size: $f16;
		}
	}	
	.meta_publication{
		font-size: $f16;
		margin: 35px 0 0 0;
	}
	.meta_evenement{
		padding: 30px 0px 35px 0px;
		font-size: $f24;
		margin-right: 30px;
		width: 130px;
		.article{
		font-size: $f16;
		}
	}
	.listeagenda_item{
		.date_evenement{
			display: block;
			float: left;
		}
		.texte_evenement{
			display: block;
		}
	}
	.listeactu{
		text-align: center;
	}
	.listeactu_actu{
		float: none;
		width: 272px;
		margin: 15px 10px;
		text-align: left;
	}
	.filtre{
		line-height: 1.2;
		margin: 20px 0;
	}
	.pagination{
		line-height: 1.2;
		padding-top: 20px;
	}
	.infos_pratiques{
		.tabs__list{
			text-align: center;
			font-size: 0;
			
		}
		.tabs__item{
			font-size: $f16;
			display: inline-block;
		}
	}
	.glossaire{
		margin-top: 20px;
	}
	.wrapper-annuaire{
		text-align: center;
	}
	.personne{
		float: none;
		width: 230px;
		margin: 12px 12px;
		text-align: left;
	}
	.extranet {
		.filtre{ 
			.ui-selectmenu-button, .filtre_input, .filtre_button {
				margin-bottom: 10px;
			}
			.filtre_input{
				margin-left: 0;
			}
		}
	}
	.thematiques_home{
		text-align: center;
		margin: 50px 0;
		.titre3{
			margin: 0;
			text-align: left;
		}
	}
	.contenu-editable.contenu_thematiques {
		margin-top: 100px;
	}
	.elmt_thematique{
		.tn3 a{
		}
	}
}

@media screen and (max-width: 920px){ 
	.conteneur_principal{
		padding-bottom: 0;
	}
	.profil_info{
		.portrait{
			float: none;
			margin-bottom: 10px;
		}
	}
}


